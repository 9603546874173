import { render, staticRenderFns } from "./FolderOfferItem.vue?vue&type=template&id=0e425de0&scoped=true"
import script from "./FolderOfferItem.vue?vue&type=script&lang=js"
export * from "./FolderOfferItem.vue?vue&type=script&lang=js"
import style0 from "./FolderOfferItem.vue?vue&type=style&index=0&id=0e425de0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e425de0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VTooltip})
