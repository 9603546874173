<template>
  <div
    v-if="/^FILE|SIGNATURE$/.test(question.typeQuestion)"
    class="wizidee_data_container"
  >
    <template>
      <a :href="question.answer.content" target="_blank" class="personal_file">
        <BaseFrontOfficeImage
          :src="question.answer.content[0].url"
          class="full_image"
        />
      </a>
      <div class="wizidee_data">
        <div
          v-for="(data, i) in wizideeDataList"
          :key="`data${i}`"
          class="data"
        >
          <div class="key" v-text="data.key" />
          <div v-if="data.isImage" class="value">
            <BaseFrontOfficeImage :src="data.value" />
          </div>
          <div v-else class="value" v-text="data.value" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "FolderQuestionWizideeData",
  props: {
    extractedData: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    wizideeDataList() {
      return [
        {
          key: this.$t("utils.authority"),
          value: this.question.answer.extractedData.authority,
        },
        {
          key: this.$t("utils.id"),
          value: this.question.answer.extractedData.permitId,
        },
        {
          key: this.$t("utils.permitList"),
          value: this.question.answer.extractedData.permitList,
        },
        {
          key: this.$t("utils.useName"),
          value: this.question.answer.extractedData.useName,
        },
        {
          key: this.$tc("utils.name", 1),
          value: this.question.answer.extractedData.name,
        },
        {
          key: this.$t("utils.firstName"),
          value: this.question.answer.extractedData.firstName,
        },
        {
          key: this.$t("utils.birthDate"),
          value: this.question.answer.extractedData.birthDate,
        },
        {
          key: this.$t("utils.birthPlace"),
          value: this.question.answer.extractedData.birthPlace,
        },
        {
          key: this.$t("utils.height"),
          value: this.question.answer.extractedData.height,
        },
        {
          key: this.$t("utils.addressFullText"),
          value: this.question.answer.extractedData.addressFullText,
        },
        {
          key: this.$t("utils.validityDate"),
          value: this.question.answer.extractedData.validityDate,
        },
        {
          key: this.$tc("utils.releaseDate", 1),
          value: this.question.answer.extractedData.releaseDate,
        },
        {
          key: this.$tc("utils.iban"),
          value: this.question.answer.extractedData.iban,
        },
        {
          key: this.$tc("utils.bic"),
          value: this.question.answer.extractedData.bic,
        },
        {
          key: this.$tc("utils.holder"),
          value: this.question.answer.extractedData.holder,
        },
        {
          key: this.$tc("utils.socialNumber"),
          value: this.question.answer.extractedData.socialNumber,
        },
        {
          key: this.$t("utils.signature"),
          value: this.question.answer.extractedData.signature,
          isImage: true,
        },
        {
          key: this.$t("utils.photo"),
          value: this.question.answer.extractedData.photo,
          isImage: true,
        },
      ].filter((wd) => wd.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.wizidee_data_container {
  padding: 16px;
  display: flex;
  .personal_file {
    display: inline-block;
    width: 50%;
    flex: none;
  }
  .wizidee_data {
    flex-grow: 1;
    margin-left: 24px;
    .data {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      margin-bottom: 0.7em;
      .key {
        flex: none;
        font-size: 14px;
        color: #919191;
      }
      .value {
        margin-left: 16px;
        color: #4f4f4f;
        word-break: break-all;
      }
    }
  }
}
</style>
