import { cosmoAPI, cosmoWEB } from "@/core/Api/cosmoAxiosInstance";

export default {
  deleteCourseStep(organizationId, courseId, stepId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/`
    );
  },
  deleteCourseStepQuestion(organizationId, courseId, stepId, questionId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/`
    );
  },
  deleteLogicalJump(organizationId, courseId, stepId, questionId, jumpId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump/${jumpId}/`
    );
  },
  deleteQuestionOption(organizationId, courseId, stepId, questionId, optionId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/option/${optionId}/`
    );
  },
  deleteOrganizationCourse(organizationId, courseId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/`
    );
  },
  getEligibledLogicalJumps(organizationId, courseId, stepId, questionId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump/eligible/`
    );
  },
  getQuestionsLogicalJumps(organizationId, courseId, stepId, questionId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump/`
    );
  },
  getQuestionOptions(organizationId, courseId, stepId, questionId, page) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/option/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getOrganizationCourse(organizationId, courseId) {
    return cosmoAPI.get(`/organization/${organizationId}/course/${courseId}/`);
  },

  getOrganizationCourseCampaigns(organizationId, courseId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/campaign/`
    );
  },
  getOrganizationCourseStep(organizationId, courseId, stepId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/`
    );
  },
  getOrganizationCourses(organizationId, archived = false, page) {
    return cosmoAPI.get(`/organization/${organizationId}/course/`, {
      params: {
        page,
        archived,
      },
    });
  },

  moveQuestion(organizationId, courseId, stepId, questionId, goUp) {
    return cosmoWEB.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/${
        goUp ? "up" : "down"
      }/`
    );
  },
  moveQuestionDrop(organizationId, courseId, stepId, questionId, newPosition) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/order/`,
      {
        order: +newPosition,
      }
    );
  },
  moveStep(organizationId, courseId, stepId, goUp) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/${
        goUp ? "up" : "down"
      }/`
    );
  },
  patchCourseStep(organizationId, courseId, stepId, step) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/`,
      step
    );
  },
  patchCourseStepQuestion(
    organizationId,
    courseId,
    stepId,
    questionId,
    question
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/`,
      question
    );
  },
  patchCourseStepQuestionOption(
    organizationId,
    courseId,
    stepId,
    questionId,
    optionId,
    option
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/option/${optionId}/`,
      option
    );
  },
  patchLogicalJump(organizationId, courseId, stepId, questionId, jump) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump/`,
      jump
    );
  },
  patchOrganizationCourse(organizationId, courseId, course) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/`,
      course,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  findCourseStep(organizationId, courseId, stepId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/`
    );
  },
  postCourseStep(organizationId, courseId, step) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/`,
      step
    );
  },
  postCourseStepQuestion(
    organizationId,
    courseId,
    stepId,
    question,
    isPersonalData
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${
        isPersonalData ? "datatype/" : ""
      }`,
      question,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postCourseStepQuestionOption(
    organizationId,
    courseId,
    stepId,
    questionId,
    option
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/option/`,
      option
    );
  },
  postLogicalJump(organizationId, courseId, stepId, questionId, jump) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump/`,
      jump
    );
  },
  postOrganizationCourse(organizationId, course) {
    return cosmoAPI.post(`/organization/${organizationId}/course/`, course);
  },

  postToggleArchiveOrganizationCourse(organizationId, courseId, archived) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/archive/`,
      { archive: archived }
    );
  },

  postOrganizationCourseCampaign(organizationId, courseId, campaign) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/`,
      campaign
    );
  },
  postOrganizationDuplicateCourse(organizationId, courseId, course) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/duplicate/`,
      course
    );
  },
  putOrganizationCourse(organizationId, courseId, course) {
    return cosmoAPI.put(
      `/organization/${organizationId}/course/${courseId}/`,
      course
    );
  },
  getQuestionGroups(organizationId, courseId, stepId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/group/`
    );
  },
  postQuestionGroup(organizationId, courseId, stepId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/group/`,
      payload
    );
  },
  deleteQuestionGroup(organizationId, courseId, stepId, groupId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/group/${groupId}/`
    );
  },
  //#region Organization > Course > Campaign - API
  createOrganizationCourseCampaign(organizationId, courseId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/`,
      payload
    );
  },
  getOrganizationCourseCampaign(organizationId, courseId, campaignId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/`
    );
  },
  patchOrganizationCourseCampaign(
    organizationId,
    courseId,
    campaignId,
    payload
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/`,
      payload
    );
  },
  deleteOrganizationCourseCampaign(organizationId, courseId, campaignId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/`
    );
  },
  postOrganizationCourseCampaignInvite(
    organizationId,
    courseId,
    campaignId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/invite/`,
      payload
    );
  },
  //#endregion
  //#region Organization > Course > Campaign > Record - API
  getOrganizationCourseCampaignRecordsAgreed(
    organizationId,
    courseId,
    campaignId,
    page
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/records-agreed/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getOrganizationCourseCampaignRecordsDisagreed(
    organizationId,
    courseId,
    campaignId,
    page
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/records-disagreed/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getOrganizationCourseCampaignRecordsInProgress(
    organizationId,
    courseId,
    campaignId,
    page
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/records-in-progress/`,
      {
        params: {
          page,
        },
      }
    );
  },
  //#endregion
};
