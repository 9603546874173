<template>
  <div class="email_item_container">
    <!-- HEADER -->
    <folder-control-header
      :expanded="expanded"
      :name="control.validationSchema"
      :date-created="control.dateCreated"
      :status="control.status"
      @expandChange="expanded = !expanded"
    />

    <!-- BODY -->
    <template v-if="expanded">
      <div
        class="item_body"
        :class="{
          error_body: ['ERROR', 'FAILED'].includes(control.status),
          success: control.status === 'SUCCESS',
        }"
      >
        <div class="separator" />
        <div class="item_content">
          <div class="email_details">
            <!-- CONTACT -->

            <div class="item__title">
              {{ $t("utils.errorReason") }}
            </div>
            <div class="message__container">
              <div
                v-for="message in control.reasons"
                :key="message"
                class="message"
                v-text="message"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FolderControlHeader from "./FolderControlHeader.vue";

export default {
  name: "FolderControlItem",
  components: {
    FolderControlHeader,
  },
  props: {
    control: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
  },
};
</script>

<style lang="scss" scoped>
.email_item_container {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;

  .item_body {
    border-left: 32px solid #b6bdff;
    position: relative;
    padding-bottom: 34px;
    &.success {
      border-left: 32px solid #18a300;
    }
    &.error_body {
      border-left: 32px solid #ff5267;
    }

    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .item_content {
      padding: 24px;

      .email_details {
        padding: 16px 24px;
        .email_detail_item {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #353542;
          }
        }
      }
      .item__title {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 150% */
        margin-bottom: 24px;
      }
      .message__container {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 16px;
        gap: 10px;
        border: 0.5px solid #cfcfdc;
        background: #fff;
        .message {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
