<template>
  <div>
    <section class="mb-6">
      <div class="bo_title mb-6" v-text="$tc('organization.course', 1)" />
      <BaseCard class="pa-6 mb-6" flat>
        <!-- LOADING STATE -->
        <BaseRow v-if="isFetchingCourse" no-gutters>
          <BaseCol :cols="5">
            <BaseSkeletonLoader type="image" width="100%" />
          </BaseCol>
          <BaseCol :cols="7">
            <div class="mb-4">
              <BaseSkeletonLoader type="heading" class="mb-4" />
              <BaseSkeletonLoader type="text" width="144px" class="mb-6" />
              <BaseSkeletonLoader type="text@5" />
            </div>
            <div class="d-flex flex-wrap justify-end align-end">
              <BaseSkeletonLoader
                v-for="n in 4"
                :key="n"
                type="image"
                height="28px"
                width="104px"
                class="ml-4 mt-4"
              />
            </div>
          </BaseCol>
        </BaseRow>
        <!-- DISPLAY COURSE STATE -->
        <BaseRow v-else-if="!!course" no-gutters>
          <BaseCol :cols="5">
            <div
              class="relative rounded-4"
              style="padding-top: 66.6667%; overflow: hidden"
            >
              <CourseDetailImageForm
                :image="course.image"
                :archived="course.archived"
                class="absolute inset-0"
                @deleteImage="course.image = null"
              />
            </div>
          </BaseCol>
          <BaseCol
            :cols="7"
            class="pl-4 d-flex flex-column flex-wrap justify-space-between"
          >
            <div class="mb-4">
              <BaseTitle class="mb-4">
                <span v-if="course.archived" class="archived-title">
                  {{ $t("course.archivedTitle") }}
                </span>
                {{ course.name }}
              </BaseTitle>
              <BaseParagraph
                text="secondary"
                uppercase
                class="mb-6 d-block"
                v-text="organizationName"
              />
              <BaseMarkdownViewer :content="course.description" />
            </div>
            <div
              class="align-self-end d-flex flex-wrap flex-row justify-end align-end"
            >
              <BaseButton
                v-if="!course.archived"
                type="primary"
                small
                icon="$mdiPencilOutline"
                class="ml-4 mt-4"
                @click="isCreateAndEditCourseDialogOpen = true"
              >
                {{ $t("utils.edit") }}
              </BaseButton>
              <BaseButton
                v-if="!course.archived"
                :disabled="!course.isValid"
                type="primary"
                small
                icon="$mdiEyeOutline"
                class="ml-4 mt-4"
                @click="goToPreview(course.testUrl)"
              >
                {{ $t("utils.seeDraft") }}
              </BaseButton>
              <BaseButton
                v-if="!course.archived"
                type="primary"
                small
                icon="$mdiContentCopy"
                class="ml-4 mt-4"
                @click="isDuplicateCourseDialogOpen = true"
              >
                {{ $t("utils.duplicate") }}
              </BaseButton>
              <div v-if="course.steps.length === 0">
                <BaseButton
                  type="primary"
                  color="secondary"
                  small
                  icon="$mdiTrashCanOutline"
                  class="ml-4 mt-4"
                  @click="isDeleteCourseDialogOpen = true"
                >
                  {{ $t("utils.delete") }}
                </BaseButton>
              </div>
              <div v-if="course.steps.length > 0">
                <BaseButton
                  type="primary"
                  color="primary"
                  small
                  icon="$mdiArchive"
                  class="ml-4 mt-4"
                  @click="isCourseToggleArchivedDialogOpen = true"
                >
                  {{
                    !course.archived
                      ? $t("utils.archive")
                      : $t("utils.unarchive")
                  }}
                </BaseButton>
              </div>
              <div v-if="deleteButtonStatus">
                <BaseButton
                  type="secondary"
                  color="secondary"
                  small
                  icon="$mdiTrashCanOutline"
                  class="ml-4 mt-4"
                  @click="isDeleteCourseDialogOpen = true"
                >
                  {{ $t("utils.delete") }}
                </BaseButton>
              </div>
            </div>
          </BaseCol>
        </BaseRow>
        <!-- NOT FOUNDED COURSE -->
        <BaseRow v-else>
          <BaseCol :cols="7">
            <BaseTitle v-text="$t('course.notFound')" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
      <!-- Record Steps -->
      <Board v-if="!!course" class="mb-6">
        <template #header>
          <div class="mb-6">
            <div class="bo_title mb-2" v-text="$tc('course.step', 10)" />
            <BaseParagraph
              text="secondary"
              v-text="$t('course.stepDescription')"
            />
          </div>
        </template>
        <template #main>
          <div class="text-center mb-6">
            <BaseButton
              v-if="!course.hasCampaignStarted && !course.steps.length"
              type="tertiary"
              color="primary"
              icon="$mdiPlus"
              @click="
                () => {
                  isCreateAndEditCourseStepDialogOpen = true;
                  activeCreateStepButtonOrder = 0;
                }
              "
            >
              {{ $t("course.addStep") }}
            </BaseButton>
          </div>
          <div
            v-for="(step, index) in course.steps
              .slice()
              .sort((a, b) => a.order - b.order)"
            :key="step.id"
          >
            <!-- is-editing-allowed props has been set to true. But it can disable all edition in a course -->
            <CourseDetailStep
              :step="step"
              :course-is-anonymous="course.anonymous"
              is-editing-allowed
              :is-first-step="index === 0"
              :is-last-step="index === course.steps.length - 1"
              class="mb-6"
              @edit="
                () => {
                  $store.dispatch('course/setActiveStep', step);
                  isCreateAndEditCourseStepDialogOpen = true;
                }
              "
              @delete="
                () => {
                  $store.dispatch('course/setActiveStep', step);
                  isDeleteCourseStepDialogOpen = true;
                }
              "
              @edit-question="
                (question) => {
                  $store.dispatch('course/setActiveStep', step);
                  $store.dispatch('course/setActiveQuestion', question);
                  isCreateAndEditCourseQuestionDialogOpen = true;
                }
              "
              @create-question="
                (val) => {
                  activeNewQuestionClass = val;
                  $store.dispatch('course/setActiveStep', step);
                  isCreateAndEditCourseQuestionDialogOpen = true;
                }
              "
              @delete-question="
                (question) => {
                  $store.dispatch('course/setActiveStep', step);
                  $store.dispatch('course/setActiveQuestion', question);
                  isDeleteCourseStepQuestionDialogOpen = true;
                }
              "
              @logicjump="
                (question) => {
                  $store.dispatch('course/setActiveStep', step);
                  $store.dispatch('course/setActiveQuestion', question);
                  isCourseQuestionLogicjumpEditorOpen = true;
                }
              "
              @create-question-group="
                (questionGroup) => {
                  questionsToGroupPayload = questionGroup;
                  $store.dispatch('course/setActiveStep', step);
                  isCreateAndEditQuestionsGroupDialogOpen = true;
                }
              "
              @delete-group="
                (group) => {
                  activeGroup = group;
                  $store.dispatch('course/setActiveStep', step);
                  isDeleteQuestionsGroupDialogOpen = true;
                }
              "
            />
          </div>
          <div class="text-center">
            <BaseButton
              v-if="!course.hasCampaignStarted && course.steps.length"
              type="tertiary"
              color="primary"
              icon="$mdiPlus"
              class="mb-6"
              @click="
                () => {
                  isCreateAndEditCourseStepDialogOpen = true;
                  activeCreateStepButtonOrder =
                    orderedCourseSteps[orderedCourseSteps.length - 1].order + 1;
                }
              "
            >
              {{ $t("course.addStep") }}
            </BaseButton>
          </div>
        </template>
      </Board>
      <!-- RECORD CAMPAIGNS -->
      <CourseDetailCampaigns v-if="!!course && !!courseCampaigns" />
    </section>
    <!-- DIALOG SECTION -->
    <div v-if="!isFetchingCourse && !!course">
      <CourseCreateAndEditDialog
        :is-open="isCreateAndEditCourseDialogOpen"
        @close="isCreateAndEditCourseDialogOpen = false"
      />
      <CourseDeleteDialog
        :is-open="isDeleteCourseDialogOpen"
        @close="isDeleteCourseDialogOpen = false"
      />
      <CourseDuplicateDialog
        :is-open="isDuplicateCourseDialogOpen"
        @close="isDuplicateCourseDialogOpen = false"
      />
      <CourseQuestionCreateAndEditDialog
        :is-open="isCreateAndEditCourseQuestionDialogOpen"
        :question-class="activeQuestionClass"
        @close="resetQuestionEditorState()"
      />
      <CourseQuestionDeleteDialog
        :is-open="isDeleteCourseStepQuestionDialogOpen"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            $store.dispatch('course/setActiveQuestion', null);
            isDeleteCourseStepQuestionDialogOpen = false;
          }
        "
      />
      <CourseQuestionLogicJumpEditor
        v-if="activeQuestion"
        :course-id="+$route.params.courseId"
        :is-open="isCourseQuestionLogicjumpEditorOpen"
        :organization-id="+$route.params.organizationId"
        :question="activeQuestion"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            $store.dispatch('course/setActiveQuestion', null);
            isCourseQuestionLogicjumpEditorOpen = false;
          }
        "
        @update="updateLogicalJumps($event)"
      />
      <CourseStepCreateAndEditDialog
        :is-open="isCreateAndEditCourseStepDialogOpen"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            activeCreateStepButtonOrder = NaN;
            isCreateAndEditCourseStepDialogOpen = false;
          }
        "
      />
      <CourseStepDeleteDialog
        v-if="course.steps && course.steps.length"
        :is-open="isDeleteCourseStepDialogOpen"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            isDeleteCourseStepDialogOpen = false;
          }
        "
      />
      <CourseQuestionGroupCreateAndEditDialog
        v-if="course.steps && course.steps.length"
        :is-open="isCreateAndEditQuestionsGroupDialogOpen"
        :questions-to-group="questionsToGroupPayload"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            isCreateAndEditQuestionsGroupDialogOpen = false;
          }
        "
      />
      <CourseQuestionGroupDeleteDialog
        v-if="course.steps && course.steps.length"
        :is-open="isDeleteQuestionsGroupDialogOpen"
        :group="activeGroup"
        @close="
          () => {
            $store.dispatch('course/setActiveStep', null);
            isDeleteQuestionsGroupDialogOpen = false;
          }
        "
      />
      <CourseToggleArchiveDialog
        v-if="course.steps && course.steps.length"
        :is-open="isCourseToggleArchivedDialogOpen"
        @close="isCourseToggleArchivedDialogOpen = false"
      />
      <CampaignCreateAndEditDialog />
    </div>
  </div>
</template>

<script>
import Board from "@/core/Components/Board.vue";
import CourseCreateAndEditDialog from "../Components/CourseCreateAndEditDialog";
import CourseQuestionCreateAndEditDialog from "../Components/CourseQuestionCreateAndEditDialog";
import CourseQuestionDeleteDialog from "../Components/CourseQuestionDeleteDialog";
import CourseQuestionLogicJumpEditor from "../Components/CourseQuestionLogicjumpEditor";
import CourseDeleteDialog from "../Components/CourseDeleteDialog";
import CourseDetailCampaigns from "../Components/CourseDetailCampaigns";
import CourseDetailImageForm from "../Components/CourseDetailImageForm";
import CourseDetailStep from "../Components/CourseDetailStep";
import CourseDuplicateDialog from "../Components/CourseDuplicateDialog";
import CourseStepCreateAndEditDialog from "../Components/CourseStepCreateAndEditDialog";
import CourseStepDeleteDialog from "../Components/CourseStepDeleteDialog";
import CourseQuestionGroupCreateAndEditDialog from "../Components/CourseQuestionGroupCreateAndEditDialog";
import CourseQuestionGroupDeleteDialog from "../Components/CourseQuestionGroupDeleteDialog";
import CourseToggleArchiveDialog from "../Components/CourseToggleArchiveDialog";
import CampaignCreateAndEditDialog from "../Components/CampaignCreateAndEditDialog";

import { mapState, mapGetters } from "vuex";

export default {
  name: "EditorDetail",
  components: {
    Board,
    CourseCreateAndEditDialog,
    CourseQuestionCreateAndEditDialog,
    CourseQuestionDeleteDialog,
    CourseQuestionLogicJumpEditor,
    CourseDeleteDialog,
    CourseDetailCampaigns,
    CourseDetailImageForm,
    CourseDetailStep,
    CourseDuplicateDialog,
    CourseStepCreateAndEditDialog,
    CourseStepDeleteDialog,
    CourseQuestionGroupCreateAndEditDialog,
    CourseQuestionGroupDeleteDialog,
    CourseToggleArchiveDialog,
    CampaignCreateAndEditDialog,
  },
  data() {
    return {
      archived: false,
      activeNewQuestionClass: "",
      activeCreateStepButtonOrder: NaN,
      isCourseQuestionLogicjumpEditorOpen: false,
      isCreateAndEditCourseDialogOpen: false,
      isCreateAndEditCourseQuestionDialogOpen: false,
      isCreateAndEditCourseStepDialogOpen: false,
      isDeleteCourseDialogOpen: false,
      isDeleteCourseStepQuestionDialogOpen: false,
      isDeleteCourseStepDialogOpen: false,
      isDuplicateCourseDialogOpen: false,
      isCreateAndEditQuestionsGroupDialogOpen: false,
      isDeleteQuestionsGroupDialogOpen: false,
      isCourseToggleArchivedDialogOpen: false,

      questionsToGroupPayload: [],
      activeGroup: null,
    };
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      courseCampaigns: (state) => state.course.campaigns,
      activeStep: (state) => state.course.activeStep,
      activeQuestion: (state) => state.course.activeQuestion,
    }),
    ...mapGetters({
      isFetchingCourse: "course/isCourseLoading",
      isFetchingCourseCampaigns: "course/isCampaignsLoading",
    }),
    activeQuestionClass() {
      if (this.activeNewQuestionClass) {
        return this.activeNewQuestionClass;
      }
      for (let index = 0; index < this.course.steps.length; index++) {
        const question = this.course.steps[index].questions.find(
          (question) => question.id == this.activeQuestion?.id
        );
        if (question && question.typeQuestion === "MESSAGE") {
          return "MESSAGE";
        }
        if (question) {
          return "QUESTION";
        }
      }
      return "QUESTION";
    },
    organizationName() {
      return this.$store.state.organization.activeOrganization.name;
    },
    orderedCourseSteps() {
      return this.course.steps.slice().sort((a, b) => a.order - b.order);
    },
    deleteButtonStatus() {
      let areCampaignsDraft = true;
      if (this.courseCampaigns && this.courseCampaigns.length > 0) {
        this.courseCampaigns.forEach((campaign) => {
          areCampaignsDraft =
            areCampaignsDraft && /^DRAFT$/.test(campaign.status);
        });
      }
      return this.course.archived && areCampaignsDraft;
    },
  },
  watch: {
    "$route.params.courseId": {
      handler() {
        this.reset();
        this.initialize();
      },
      immediate: true,
    },
    course: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    this.reset();
  },
  methods: {
    goToPreview(item) {
      open(`${process.env.VUE_APP_WALLET_URL}${item}?isPreview=true`);
    },
    reset() {
      /* Reset store data */
      this.$store.dispatch("course/resetCourse");
      this.$store.dispatch("course/resetCampaigns");
    },
    async initialize() {
      /* Fetch active course */
      try {
        await this.$store.dispatch("course/fetchCourse", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
        });
        await this.$store.dispatch("course/fetchCampaigns", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
        });
      } catch (error) {
        const { status, statusText } = error && error?.response;
        if (status === 404) {
          this.$router.push({
            name: "folderList",
            params: {
              organizationId: this.$route.params.organizationId,
            },
          });
        }
        if (status === 500) throw new Error(statusText);
      }
    },
    resetQuestionEditorState() {
      this.activeNewQuestionClass = "";
      this.$store.dispatch("course/setActiveStep", null);
      this.$store.dispatch("course/setActiveQuestion", null);
      this.isCreateAndEditCourseQuestionDialogOpen = false;
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[1].label = `${this.course.name} `;
    },
  },
};
</script>

<style scoped>
.archived-title {
  color: grey;
}
</style>
