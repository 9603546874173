var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"cl":""}},[_c('BaseModal',{attrs:{"width":850,"value":_vm.isSendMailDialogOpen,"modal-title":_vm.$t('utils.emailAction.sendMail')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.reset()},"click:outside":function($event){return _vm.reset()},"close":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('div',{staticClass:"container"},[_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s("Destinataires")}}),_c('div',{staticClass:"destinataire"},[_c('span',{staticClass:"label",domProps:{"textContent":_vm._s("A")}}),_c('v-combobox',{staticClass:"email_input elevation-0",attrs:{"chips":"","clearable":"","multiple":"","outlined":"","dense":"","append-icon":!_vm.isCc ? '$emailCcIcon' : ''},on:{"click:append":function($event){_vm.isCc = !_vm.isCc}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mail-chips",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove('to', item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}),(_vm.isCc)?_c('span',{staticClass:"label",domProps:{"textContent":_vm._s("Cc")}}):_vm._e(),(_vm.isCc)?_c('v-combobox',{staticClass:"email_input elevation-0",attrs:{"chips":"","clearable":"","multiple":"","outlined":"","dense":"","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mail-chips",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove('cc', item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1552431325),model:{value:(_vm.form.cc),callback:function ($$v) {_vm.$set(_vm.form, "cc", $$v)},expression:"form.cc"}}):_vm._e()],1),_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s("Email")}}),_c('div',{staticClass:"email-container"},[_c('span',{staticClass:"label",domProps:{"textContent":_vm._s("Objet")}}),_c('BaseTextField',{staticClass:"offer-item",attrs:{"autofocus":"","dense":"","name":"name"},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}}),_c('span',{staticClass:"label",domProps:{"textContent":_vm._s("Message")}}),_c('BaseMarkdownEditor',{attrs:{"name":"Message","with-link":false},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s("Fichiers")}}),_c('div',{staticClass:"file-container"},[_c('folder-offer-file-drag',{staticClass:"file-drag",class:_vm.error ? 'text-error' : '',attrs:{"offer-files":_vm.files,"is-multiple":""},on:{"file-droped":_vm.fileInserted,"delete-offer-file":_vm.removeFile,"file-name-changed":_vm.updateFileName}}),(_vm.error)?_c('p',{staticClass:"text-error"},[_vm._v("Fichier manquant")]):_vm._e()],1)])]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"color":"primary","type":"primary"},on:{"click":_vm.sendMail}},[_vm._v(" "+_vm._s(_vm.$t("utils.send"))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }