import Admin from "../Pages/Admin.vue";
import WorkflowDetail from "../Pages/WorkflowDetail.vue";

export default [
  {
    path: "admin/",
    name: "admin",
    component: Admin,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "organizationDashboard",
          disabled: false,
          label: "utils.dashboard",
        },
        {
          name: "admin",
          disabled: true,
          label: "utils.organizationSetting",
        },
        {
          name: "admin",
          disabled: true,
          label: "organization.avatar.chatBot",
          params: {
            activeTab: 0,
          },
        },
      ],
    },
  },
  {
    path: "workflow/:workflowId",
    name: "workflowDetail",
    component: WorkflowDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "organizationDashboard",
          disabled: false,
          label: "utils.dashboard",
        },
        {
          name: "admin",
          disabled: false,
          label: "utils.organizationSetting",
        },
        {
          name: "admin",
          disabled: false,
          label: "workflow.management",
          params: {
            activeTab: 2,
          },
        },
        {
          name: "workflowDetail",
          disabled: true,
          label: "utils.workflowSpecific",
        },
      ],
    },
  },
];
