import { render, staticRenderFns } from "./WorkflowDetailStatus.vue?vue&type=template&id=39e232c6&scoped=true"
import script from "./WorkflowDetailStatus.vue?vue&type=script&lang=js"
export * from "./WorkflowDetailStatus.vue?vue&type=script&lang=js"
import style0 from "./WorkflowDetailStatus.vue?vue&type=style&index=0&id=39e232c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e232c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VTab,VTabItem,VTabs,VTabsItems})
