var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{staticClass:"pa-6",attrs:{"is-rounded":""}},[_c('div',{staticClass:"d-flex flex-column"},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(_vm.cartTitle)}}),(_vm.folderInfo)?_c('div',[(_vm.folder)?_c('BaseCaption',{attrs:{"text":"secondary","uppercase":""},domProps:{"textContent":_vm._s(
          _vm.$t('folder.createdAt', {
            date: _vm.$d(new Date(_vm.folder.dateCreated), 'detailed'),
          })
        )}}):_vm._e(),_vm._l((_vm.data),function(context,index){return _c('FullDataTable',{key:(context + "-" + index),attrs:{"cart-title":context.name,"item-data":context.questions}})})],2):_vm._e(),(_vm.clientCollect)?_c('div',_vm._l((_vm.data),function(context,index){return _c('div',{key:(context + "-" + index),staticClass:"d-flex flex-column"},[_c('BaseFrontOfficeTitle',{staticClass:"my-2",attrs:{"color":"primary"},domProps:{"textContent":_vm._s(context.course.name)}}),_c('BaseParagraph',{domProps:{"textContent":_vm._s(_vm.getFullClientData(context.client))}}),_c('BaseCaption',{attrs:{"text":"secondary","uppercase":""},domProps:{"textContent":_vm._s(
            _vm.$tc('folder.clientFullData.consentDate', 1, {
              dateCreated: _vm.$d(new Date(context.dateCreated), 'detailed'),
              consentDate: _vm.$d(new Date(context.consentDate), 'detailed'),
            })
          )}}),_vm._l((context.steps),function(step,i){return _c('FullDataTable',{key:i,attrs:{"cart-title":step.name,"item-data":step.questions}})})],2)}),0):_vm._e(),(_vm.offerInfo)?_c('div',_vm._l((_vm.data),function(offer,index){return _c('div',{key:(offer + "-" + index),staticClass:"d-flex flex-column"},[_c('BaseFrontOfficeTitle',{staticClass:"my-2",attrs:{"color":"primary"},domProps:{"textContent":_vm._s(offer.title)}}),_c('BaseCaption',{attrs:{"text":"secondary","uppercase":""},domProps:{"textContent":_vm._s(
            _vm.$tc('folder.clientFullData.consentDate', 2, {
              dateCreated: _vm.$d(new Date(offer.dateCreated), 'detailed'),
            })
          )}}),_vm._l((_vm.getClient(offer)),function(_client,i){return _c('FullDataTable',{key:i,attrs:{"cart-title":"Client","item-data":_vm.getClientData(_client, offer),"is-offer-detail":"","is-offer-client":""}})}),_c('FullDataTable',{attrs:{"cart-title":_vm.$tc('folder.records.file', 3),"item-data":_vm.getOfferFile(offer),"is-offer-detail":"","is-offer-file-client":""}})],2)}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }