<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.assignOwnerForm.title')"
    is-complex
    width="900"
    @close="reset"
  >
    <template #modal>
      <BaseSelect
        v-model="selected"
        :loading="isFetchingContacts"
        :menu-props="{ offsetY: true }"
        :items="contacts"
        item-text="email"
        item-value="id"
        solo
      />
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click="submit">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import ClientAPI from "@/modules/Client/Services/client.api";

export default {
  name: "FolderChangeOwnerDialog",
  data: function () {
    return {
      selected: -1,
      folderId: null,
      contacts: [],
      isFetchingContacts: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isFolderChangeClient,
      activeFolder: (state) => state.folder.activeFolder,
    }),
  },

  watch: {
    async dialogState(val) {
      if (val) {
        await this.fetchOrganizationContacts();
      }
      if (val && this.selected == -1) {
        this.selected = this.activeFolder?.client?.id;
      }
    },
  },
  methods: {
    reset() {
      this.$store.commit("folder/setFolderChangeClient", false);
    },
    async submit() {
      let organizationId = this.$route.params.organizationId;
      let folderId = this.$route.params.folderId;
      let payload = { client: this.selected };
      if (this.selected !== -1)
        try {
          await this.$store.dispatch("folder/updateFolder", {
            organizationId,
            folderId,
            payload,
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.success"),
            type: "SUCCESS",
          });
        } catch {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.error"),
            type: "ERROR",
          });
        }
      this.reset();
    },
    async fetchOrganizationContacts() {
      this.isFetchingContacts = true;
      this.contacts = [];
      if (!isNaN(this.$route.params.organizationId)) {
        let page = 1;
        while (page) {
          const res = await ClientAPI.getOrganizationClients(
            this.$route.params.organizationId,
            page
          );
          this.contacts = [...this.contacts, ...res.data.results];
          page = res.data.next;
        }
      }
      this.isFetchingContacts = false;
    },
  },
};
</script>
<style scoped>
.select-width {
  width: 100%;
}
</style>
