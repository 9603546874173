<template>
  <div class="offer-detail">
    <wallet-manager-banner
      :organization="organization"
      :client-list="clientList"
    />
    <div class="offer-container">
      <div class="title" v-text="offer.title" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="description" v-html="offer.description" />
    </div>

    <div
      v-if="
        client.action === 'VALIDATION' &&
        client.status !== 'ACCEPT' &&
        client.status !== 'DECLINE'
      "
      class="comment"
    >
      <div v-if="isRefused" class="btn-container">
        <h1 class="title">Commentaires</h1>
        <v-textarea
          v-model="comment"
          class="text-area"
          placeholder="Écrire ici..."
          solo-filled
          @change="onChange"
        />
        <div class="d-flex justify-end btn-container">
          <v-btn class="btn-decline" rounded @click="isRefused = false"
            >Annuler</v-btn
          >
          <v-btn class="btn-comment" rounded @click="refuseDialogState = true"
            >Refuser</v-btn
          >
        </div>
      </div>
      <div
        v-if="!isRefused && client.status !== 'DECLINE'"
        class="d-flex justify-end btn-container"
      >
        <v-btn class="btn-decline" rounded @click="isRefused = true"
          >Refuser</v-btn
        >
        <v-btn class="btn-comment" rounded @click="dialogState = true"
          >Valider</v-btn
        >
      </div>
    </div>
    <wallet-manager-validation-modal
      :is-open="dialogState"
      :title="'Validation'"
      :description="'Vous vous apprêtez à valider cette procédure.'"
      @update="update"
      @close="dialogState = false"
    />
    <wallet-manager-validation-modal
      :is-open="refuseDialogState"
      title="Refus de Validation"
      description="Vous vous apprêtez à rejeter la validation de cette procédure. "
      is-error
      @update="submit"
      @close="refuseDialogState = false"
    />
  </div>
</template>

<script>
import WalletManagerValidationModal from "./WalletManagerValidationModal.vue";
import WalletManagerBanner from "../Components/WalletManagerBanner.vue";

export default {
  components: { WalletManagerValidationModal, WalletManagerBanner },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    clientList: {
      type: Array,
      default: () => [],
    },
    selectedFile: {
      type: Object,
      default: () => {},
    },
    client: {
      type: Object,
      default: () => {},
    },
    offer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isRefused: false,
      comment: "",
      dialogState: false,
      refuseDialogState: false,
    };
  },
  methods: {
    onChange() {
      this.$emit("onchange", this.comment);
    },
    submit() {
      this.$emit("submit");
      this.refuseDialogState = false;
    },
    update() {
      this.$emit("update", "ACCEPT");
      this.dialogState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 355px;
  gap: 35px;
  padding-inline: 16px;

  @media (max-width: 960px) {
    width: 100%;
  }
  .offer-container {
    .title {
      font-family: Poppins;
      font-size: 20px !important;
      font-weight: 500;
      line-height: 24.7px;
      text-align: center;
      margin-bottom: 35px;
    }
    .description {
      overflow-y: auto;
      font-family: Poppins;
      font-size: 16px !important;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .comment {
    @media (max-width: 960px) {
      padding: 12px !important;
    }
    .btn-container {
      margin-inline: 10px;
      gap: 8px;
      .title {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 2Opx;
        font-family: Poppins;
        border-bottom: none;
        margin-bottom: 20px;
      }
      .text-area {
        align-self: stretch;
        border-radius: 20px;
        padding: 16px 24px;

        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: 1px solid #cacaca !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        ::v-deep.v-input__slot::before {
          border-style: none !important;
        }
        ::v-deep.v-input__slot::after {
          border: none !important;
        }
      }
    }
  }
  .btn-comment {
    margin-top: 24px;
    background-color: #ffd056 !important;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
  .btn-cancel {
    margin-top: 24px;
    background-color: #eeeef7 !important;
  }
  .btn-decline {
    margin-top: 24px;
    background-color: #fefefe !important;
    color: #4f4f4f;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border: 1px solide #4f4f4f;
  }
}
</style>
