<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('course.forms.question.group.title')"
    @close="$emit('close')"
  >
    <template #modal>
      <div>
        <p>
          <BaseParagraph
            v-text="$t('course.forms.question.group.questionDescription')"
          />
        </p>
        <p>
          <BaseParagraph
            v-text="
              $t('course.forms.question.group.groupementQuestionSubDescription')
            "
          />
        </p>
        <v-form ref="form" @submit.prevent="submit()">
          <BaseTextField
            v-model="form.groupLabel"
            :label="$t('course.forms.question.group.questionName')"
            :error-messages="groupLabelErrors"
            :has-error="!!groupLabelErrors"
            @change="$v.form.groupLabel.$touch()"
          />
        </v-form>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="$v.form.$invalid"
        color="primary"
        type="primary"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CourseQuestionGroupCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
    },
    questionsToGroup: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        groupLabel: "",
      },
      questionLength: null,
    };
  },
  computed: {
    groupLabelErrors() {
      if (!this.$v.form.groupLabel.$dirty) {
        return "";
      }
      if (!this.$v.form.groupLabel.required) {
        return this.$t("course.forms.question.label.requiredQuestion");
      }
      return "";
    },
  },
  validations: {
    form: {
      groupLabel: {
        required,
      },
    },
  },
  methods: {
    submit() {
      if (!this.$v.form.$invalid) {
        this.createGroupQuestions();
      }
    },
    createGroupQuestions() {
      this.questionLength = this.$store.state.course.activeStep?.questions?.length;
      let stepId = this.$store.state.course.activeStep.id;
      if (this.questionsToGroup.length >= 2) {
        this.$store
          .dispatch("course/groupQuestions", {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            stepId,
            label: this.form.groupLabel,
          })
          .then((res) => {
            this.questionsToGroup.forEach((question) => {
              let payload = {
                organizationId: this.$route.params.organizationId,
                courseId: this.$route.params.courseId,
                stepId,
                questionId: question.id,
                question: {
                  group: res.data.id,
                },
              };
              this.$store.dispatch("course/patchQuestion", payload).then(() => {
                this.$store.dispatch("course/fetchStep", {
                  organizationId: this.$route.params.organizationId,
                  courseId: this.$route.params.courseId,
                  stepId,
                });
              });
            });
            // Display TheSnackbar
            this.$store.dispatch("snackbar/active", {
              message: this.$t("course.forms.question.group.snackbar.success"),
              type: "SUCCESS",
            });
          })
          .catch(() => {
            // Display TheSnackbar
            this.$store.dispatch("snackbar/active", {
              message: this.$t("course.forms.question.group.snackbar.success"),
              type: "ERROR",
            });
          });
        this.$refs.form.reset();
        this.$v.form.$reset();
        this.$emit("close");
      }
    },
  },
};
</script>
