<template>
  <div>
    <template v-if="loading">
      <div class="status_container">
        <div class="content_title">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
        <div class="tab_header">
          <div class="skeletons">
            <v-skeleton-loader class="skeleton" type="text" />
            <v-skeleton-loader class="skeleton" type="text" />
            <v-skeleton-loader class="skeleton" type="text" />
          </div>
        </div>

        <div class="tab_item">
          <div class="tab_actions">
            <v-skeleton-loader class="skeleton" type="text" />
            <v-skeleton-loader class="skeleton" type="text" />
          </div>
          <div class="workflow_questions">
            <div class="workflow_questions_grid">
              <FolderPreviewQuestion
                v-for="i in 6"
                :key="`q-${i}`"
                :question="{}"
                loading
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="status_container">
      <div class="content_title" v-text="$t('utils.processing')" />
      <v-tabs v-model="activeTab" class="tab_header">
        <v-tab
          v-for="category in groupedQuestionsByCategory"
          :key="`gtab-${category.id + 1}`"
          class="tab_header_item"
          @change="onSelectCategory(category.id)"
        >
          {{ category.id ? category.name : $t("utils.all") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <!-- ALL QUESTIONS -->
        <v-tab-item>
          <div class="tab_item">
            <div class="tab_actions">
              <v-checkbox
                v-model="checkAllQuestions"
                color="primary"
                hide-details
              >
                <template #label>
                  <div class="check_label" v-text="$t('utils.selectAll')" />
                </template>
              </v-checkbox>
              <button
                v-if="!isArchivedFolder"
                class="tab_action_btn"
                @click="openCreateStatusQuestionDialog({ status })"
              >
                <BaseIcon class="icon" icon="$mdiPlus" />
                <span v-text="$t('workflow.status.fields.add')" />
              </button>
            </div>
            <div v-if="checkedQuestions.length" class="tab_actions">
              <v-btn
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openAnswerQuestionDialog(checkedQuestions)"
              >
                <BaseIcon left small icon="$mdiPencilOutline" />
                {{ $tc("utils.resignFields", checkedQuestions.length) }}
              </v-btn>
              <v-btn
                :disabled="isArchivedFolder"
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openSendCosmoformDialog()"
              >
                <BaseIcon left small icon="$mdiSend" />
                {{ $tc("utils.sendFields", checkedQuestions.length) }}
              </v-btn>
            </div>
            <div
              v-for="(category, index) in groupedQuestionsByCategory"
              :key="`all-${index}`"
              class="workflow_questions"
            >
              <div
                v-if="category.id"
                class="question_category"
                v-text="category.name"
              />
              <div class="workflow_questions_grid">
                <FolderPreviewQuestion
                  v-for="(question, ind) in [...category.questions].sort(
                    (a, b) => a.order - b.order
                  )"
                  :key="`${ind}${category.id + 1}`"
                  v-model="checkedQuestions"
                  :question="question"
                  :expanded="checkedQuestions && checkedQuestions.length > 0"
                  @questionClick="openAnswerQuestionDialog"
                  @editQuestion="openAnswerQuestionDialog"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <!-- OTHER QUESTIONS -->
        <v-tab-item
          v-for="category in groupedQuestionsByCategory.filter((gsq) => gsq.id)"
          :key="`gitem-${category.id + 1}`"
        >
          <div class="tab_item">
            <div class="tab_actions">
              <v-checkbox
                v-model="checkAllQuestions"
                color="primary"
                hide-details
              >
                <template #label>
                  <div class="check_label" v-text="$t('utils.selectAll')" />
                </template>
              </v-checkbox>
              <button
                v-if="!isArchivedFolder"
                class="tab_action_btn"
                @click="openCreateStatusQuestionDialog({ status, category })"
              >
                <BaseIcon class="icon" icon="$mdiPlus" />
                <span v-text="$t('workflow.status.fields.add')" />
              </button>
            </div>
            <div v-if="checkedQuestions.length" class="tab_actions">
              <v-btn
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openAnswerQuestionDialog(checkedQuestions)"
              >
                <BaseIcon left small icon="$mdiPencilOutline" />
                {{ $tc("utils.resignFields", checkedQuestions.length) }}
              </v-btn>
              <v-btn
                :disabled="isArchivedFolder"
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openSendCosmoformDialog()"
              >
                <BaseIcon left small icon="$mdiSend" />
                {{ $tc("utils.sendFields", checkedQuestions.length) }}
              </v-btn>
            </div>
            <div class="workflow_questions">
              <div class="workflow_questions_grid">
                <FolderPreviewQuestion
                  v-for="(question, ind) in [...category.questions].sort(
                    (a, b) => a.order - b.order
                  )"
                  :key="`${ind}${category.id + 1}`"
                  v-model="checkedQuestions"
                  :question="question"
                  :expanded="checkedQuestions && checkedQuestions.length > 0"
                  @questionClick="openAnswerQuestionDialog"
                  @editQuestion="openAnswerQuestionDialog"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import FolderPreviewQuestion from "@/modules/Folder/Components/FolderPreviewQuestion";

export default {
  name: "FolderWorkflowStatus",
  components: {
    FolderPreviewQuestion,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      checkedQuestions: [],
      selectedCategoryId: null,
      activeTab: 1,
      setDefaultTabCalled: false,
    };
  },
  computed: {
    ...mapState({
      activeQuestions: (state) => state.folder.activeQuestions,
    }),
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    checkAllQuestions: {
      set(checked) {
        if (this.selectedCategoryId) {
          this.checkedQuestions = checked
            ? [...this.getQuestionsByCategory(this.selectedCategoryId)]
            : [];
          return;
        }
        this.checkedQuestions = checked ? [...this.status.questions] : [];
      },
      get() {
        if (this.selectedCategoryId) {
          return (
            this.checkedQuestions.length ===
              this.getQuestionsByCategory(this.selectedCategoryId).length &&
            this.getQuestionsByCategory(this.selectedCategoryId).length !== 0
          );
        }
        return (
          this.checkedQuestions.length === this.status.questions.length &&
          this.status.questions.length !== 0
        );
      },
    },
    groupedQuestionsByCategory() {
      if (!this.status) return [];
      let groupedQuestions = this.status.categories
        .map((sc) => ({
          ...sc,
          statusId: this.status.id,
          isFirst:
            sc.order ===
            Math.min(...this.status.categories.map((tmpSC) => tmpSC.order)),
          isLast:
            sc.order ===
            Math.max(...this.status.categories.map((tmpSC) => tmpSC.order)),
          questions: this.status.questions
            .filter((q) => q.category?.id === sc.id)
            .sort((a, b) => a.order - b.order),
        }))
        .filter((sc) => sc.questions.length)
        .map((sc) => ({
          ...sc,
        }))
        .sort((a, b) => a.order - b.order);
      const withoutCategoryGroup = {
        id: null,
        name: this.$t("category.uncategorized"),
        statusId: this.status.id,
        questions: this.status.questions.filter((q) => !q.category),
      };
      groupedQuestions.unshift(withoutCategoryGroup);

      return groupedQuestions;
    },
  },
  watch: {
    activeQuestions(val) {
      if (!val || val.length === 0) {
        this.checkAllQuestions = false;
      }
    },
    status: {
      handler(newVal, old) {
        if (!old || !newVal) return;
        this.setDefaultTab();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setDefaultTab() {
      if (!this.groupedQuestionsByCategory.length || this.setDefaultTabCalled)
        return;
      this.selectedCategoryId = this.groupedQuestionsByCategory.find(
        (g) => !!g.id
      )?.id;
      this.setDefaultTabCalled = true;
    },
    onSelectCategory(categoryId) {
      this.selectedCategoryId = categoryId;
      this.checkedQuestions = [];
    },
    getQuestionsByCategory(categoryId) {
      let category = this.groupedQuestionsByCategory.find(
        (gsq) => gsq.id === categoryId
      );
      return category ? category.questions : [];
    },
    openAnswerQuestionDialog(questions) {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", questions);
      this.$store.commit("folder/SET_MODAL_ANWER_QUESTION_STATUS", true);
    },
    openSendCosmoformDialog() {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", this.checkedQuestions);
      this.$store.commit("folder/SET_MODAL_SEND_COSMO_FORM", true);
    },
    openCreateStatusQuestionDialog({ status, category = null }) {
      this.$store.commit("workflow/setActiveStatus", status);
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", category);
      this.$store.commit("folder/setModalStatusQuestionCreateDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.status_container {
  width: 100%;
  padding: 16px;
  .content_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #707080;
    .skeleton {
      height: 16px;
      width: 70px;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 12px;
        }
      }
    }
  }
  .tab_header {
    margin-top: 16px;
    .skeletons {
      display: flex;
      .skeleton {
        margin-right: 8px;
        height: 40px;
        width: 70px;
        ::v-deep {
          .v-skeleton-loader__bone {
            height: 40px;
          }
        }
      }
    }
    .tab_header_item {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .tab_item {
    padding-top: 8px;
    .tab_actions {
      display: flex;
      align-items: center;
      padding: 0 16px;
      min-height: 40px;
      flex-wrap: wrap;
      width: 100%;
      &::v-deep {
        .v-input {
          margin: 0;
          padding: 0;
        }
      }
      & > * {
        margin-top: 16px !important;
        margin-right: 16px !important;
      }
      .check_label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #707080;
      }
      .tab_action_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #f4f7ff;
        border-radius: 5px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #2536cc;
        & > .icon {
          margin-left: -2px;
          margin-right: 4px;
          &::v-deep {
            .v-icon {
              width: 16px;
              height: 16px;
              color: #2536cc;
            }
          }
        }
      }
      .skeleton {
        height: 32px;
        width: 150px;
        ::v-deep {
          .v-skeleton-loader__bone {
            height: 32px;
          }
        }
      }
    }
    .workflow_questions {
      width: 100%;
      margin-top: 24px;

      .question_category {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        margin-bottom: 16px;
      }

      .workflow_questions_grid {
        display: grid;
        padding-right: 8px;
        padding-bottom: 4px;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        max-height: 248px;
        overflow-y: auto;
        overflow-x: hidden;
        grid-template-columns: 100%;
        @media (min-width: 1400px) {
          grid-template-columns: repeat(2, calc(calc(100% - 16px) / 2));
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 3px;
          border: 1px solid #eeeef7;
        }

        &::-webkit-scrollbar-thumb {
          background: #b6bdff;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>
