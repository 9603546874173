var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseFrontOfficeTitle',{domProps:{"textContent":_vm._s(_vm.$t('wallet.signIn.title'))}}),_c('BaseFrontOfficeDivider',{staticClass:"my-4",attrs:{"type":"grey"}}),_c('v-form',[_c('BaseFrontOfficeTextField',{attrs:{"label":((_vm.$tc('utils.email', 1)) + "*"),"error-messages":_vm.emailError,"placeholder":"exemple@mail.com"},on:{"change":function($event){return _vm.$v.form.email.$touch()},"blur":function($event){return _vm.$v.form.email.$touch()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('BaseFrontOfficeTextField',{attrs:{"label":((_vm.$t('account.password')) + "*"),"type":_vm.showPassword ? 'text' : 'password',"error-messages":_vm.passwordErrors,"append-icon":_vm.showPasswordIcon,"placeholder":"•••••••••","autofocus":_vm.$route.params.invitedUserEmail ? true : false},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"change":function($event){return _vm.$v.form.password.$touch()},"blur":function($event){return _vm.$v.form.password.$touch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.submitError)?_c('BaseFrontOfficeAlert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.submitError)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-start mb-4"},[(_vm.$route.fullPath.indexOf('/wallet') != -1)?_c('BaseFrontOfficeHyperlink',{attrs:{"to":{
          name: 'WalletPasswordReset',
          query: {
            next: _vm.$route.query.next,
          },
          params: {
            accessCampaign: _vm.$route.params.accessCampaign,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("auth.reset.forgottenPassword"))+" ")]):_c('BaseFrontOfficeHyperlink',{attrs:{"to":{
          name: 'PasswordReset',
        }}},[_vm._v(" "+_vm._s(_vm.$t("auth.reset.forgottenPassword"))+" ")])],1),_c('div',{staticClass:"button-container"},[_c('BaseFrontOfficeButton',{staticClass:"ml-auto",attrs:{"icon":"$mdiCheck","disabled":_vm.$v.form.$invalid},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t("wallet.signIn.submit"))+" ")]),_c('BaseFrontOfficeButton',{staticClass:"ml-auto",on:{"click":_vm.connectMicrocrosft}},[_c('div',{staticClass:"d-flex align-center action"},[_c('BaseImage',{attrs:{"src":_vm.$findStatic('microsoftLogo'),"height":"24px","width":"24px"}}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t("wallet.signIn.submitMicrosoft"))+" ")])],1)])],1),_c('WalletHasToRegisterVue')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }