<template>
  <div>
    <div class="bo_title mb-6" v-text="$t('organization.settings')" />
    <Board class="mb-6">
      <template slot="main">
        <v-tabs v-model="tab" color="primary" slider-color="primary">
          <v-tab>
            <div class="tab_label" v-text="$t('organization.avatar.chatBot')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('organization.clientSpace')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('workflow.management')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('utils.organizationDirectory')" />
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="py-4 px-1">
            <AvatarEdition />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <ClientSpace />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <WorkflowList
              v-if="!workflowDetail"
              :organization-id="organizationId"
              @display-detail="workflowDetail = true"
            />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <OrganizationSignatoryList :organization-id="organizationId" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </Board>
    <router-view />
    <OrganizationInformationEditDialog />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import WorkflowList from "../Components/WorkflowList";
import OrganizationSignatoryList from "../Components/OrganizationSignatoryList";
import ClientSpace from "../Components/ClientSpace.vue";
import OrganizationInformationEditDialog from "../Components/OrganizationInformationEditDialog.vue";
import AvatarEdition from "../Components/AvatarEdition";

export default {
  name: "Admin",
  components: {
    Board,
    WorkflowList,
    ClientSpace,
    OrganizationInformationEditDialog,
    AvatarEdition,
    OrganizationSignatoryList,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tab: 0,
      workflowDetail: false,
      breadcrumbsLabel: [
        "organization.avatar.chatBot",
        "organization.clientSpace",
        "workflow.management",
        "utils.organizationDirectory",
      ],
    };
  },
  watch: {
    activeTab: {
      handler(newVal, oldVal) {
        if (
          (!isNaN(oldVal) && newVal) ||
          (!isNaN(newVal) && newVal !== oldVal)
        ) {
          this.tab = newVal;
        }
      },
      immediate: true,
    },
    tab: {
      handler(newVal, oldVal) {
        if (
          (!isNaN(oldVal) && newVal) ||
          (!isNaN(newVal) && newVal !== oldVal)
        ) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  methods: {
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[2].label = this.breadcrumbsLabel[this.tab];
      this.$route.meta.breadcrumbs[2].params.activeTab = this.breadcrumbsLabel[
        this.tab
      ];
    },
  },
};
</script>
