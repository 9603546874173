import DashboardAPI from "./organizationDashboard.api";

export function getOrganizationCampaigns(organizationId, page) {
  return DashboardAPI.getOrganizationCampaigns(organizationId, page);
}

export function getOrganizationCollaborators(organizationId, page) {
  return DashboardAPI.getOrganizationCollaborators(organizationId, page);
}

export function getOrganizationCourses(organizationId, page) {
  return DashboardAPI.getOrganizationCourses(organizationId, page);
}

export function getOrganizationInvitations(organizationId) {
  return DashboardAPI.getOrganizationInvitations(organizationId);
}

export function inviteCollaborator(organizationId, data) {
  return DashboardAPI.inviteCollaborator(organizationId, data);
}
export const getOrganizationStatic = (organizationID, page) => {
  return DashboardAPI.getStatisticWorkflow(organizationID, page);
};
