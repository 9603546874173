var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"record_item_container",class:{
    container_secondary: !_vm.cosmoformDetail.isExpired || _vm.closedCosmoform,
  }},[_c('FolderCosmoformItemHeader',{attrs:{"cosmoform-detail":_vm.cosmoformDetail,"expanded":_vm.expanded,"is-spam":_vm.isSpam},on:{"expandChange":function($event){return _vm.changeExpandStatus()}}}),(_vm.expanded)?[_c('div',{class:_vm.isSpam ? ' item_body is_spam' : 'item_body'},[(_vm.description)?_c('div',{staticClass:"email"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s('Email envoyé')}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"desc_content"},[_c('BaseMarkdownViewer',{attrs:{"content":_vm.description,"is-text-caption":""}})],1)])]):_vm._e(),_c('div',{staticClass:"cosmoform_detail_container"},[_c('v-tabs',{staticClass:"tab_header",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.groupedCosmoforms),function(group){return _c('v-tab',{key:("ctab-" + (group.categoryId + 1)),staticClass:"tab_header_item"},[_vm._v(" "+_vm._s(group.categoryName)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('div',{staticClass:"tab_item"},_vm._l((_vm.groupedCosmoforms.filter(
                  function (c) { return c.cosmoforms.length > 0; }
                )),function(group,index){return _c('div',{key:("all-" + index),staticClass:"grouped_questions"},[(group.categoryId)?_c('div',{staticClass:"question_category",domProps:{"textContent":_vm._s(group.categoryName)}}):_vm._e(),_c('div',{staticClass:"cosmoform_grid"},_vm._l(([].concat( group.cosmoforms ).sort(
                      function (a, b) { return a.order - b.order; }
                    )),function(cosmoform,ind){return _c('FolderPreviewQuestion',{key:("q-" + ind + (group.categoryId + 1)),attrs:{"editable":false,"disabled":!cosmoform.answer,"selectable":false,"question":Object.assign({}, cosmoform.question,
                      {answer: cosmoform.answer})},on:{"questionClick":function($event){return _vm.openQuestionValidation(cosmoform)}}})}),1)])}),0)]),_vm._l((_vm.groupedCosmoforms.filter(function (gsq) { return gsq.categoryId; })),function(group){return _c('v-tab-item',{key:("gitem-" + (group.categoryId + 1))},[_c('div',{staticClass:"tab_item"},[_c('div',{staticClass:"grouped_questions"},[_c('div',{staticClass:"cosmoform_grid"},_vm._l(([].concat( group.cosmoforms ).sort(
                      function (a, b) { return a.order - b.order; }
                    )),function(cosmoform,ind){return _c('FolderPreviewQuestion',{key:("cq-" + ind + (group.categoryId + 1)),class:!cosmoform.answer ? 'disable' : '',attrs:{"editable":false,"selectable":false,"question":Object.assign({}, cosmoform.question,
                      {answer: cosmoform.answer}),"disabled":!cosmoform.answer},on:{"questionClick":function($event){return _vm.openQuestionValidation(cosmoform)}}})}),1)])])])})],2)],1),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"relaunch_container"},[(!_vm.cosmoformDetail.isExpired || _vm.closedCosmoform)?_c('div',{staticClass:"relaunch_card"},[_c('button',{staticClass:"relaunch_btn",class:{ relaunched_btn: _vm.relaunchNumber > 0 },attrs:{"disabled":_vm.isArchivedFolder || _vm.closedCosmoform},on:{"click":function($event){return _vm.openRelaunchModal()}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiEmailOutline"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.relaunch'))}})],1),_c('div',{staticClass:"relaunch_detail",domProps:{"textContent":_vm._s(
              _vm.$t('utils.relaunchNumber', {
                value: _vm.relaunchNumber,
              })
            )}})]):_vm._e()])])]:_vm._e(),_c('folder-display-cosmo-form-answer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }