var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseDialog',{attrs:{"value":_vm.dialogState,"modal-title":_vm.modalTitle,"persistent":_vm.isSubmitting || _vm.isSubmittingValidation,"max-width":"800"},on:{"click:outside":function($event){return _vm.reset()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.reset()}},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('BaseCosmoCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"modal_header"},[_c('BaseTitle',{staticClass:"modal_title",domProps:{"textContent":_vm._s(_vm.modalTitle)}}),(_vm.visibleClient)?_c('div',{staticClass:"visible_icon"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('BaseIcon',_vm._g({attrs:{"icon":"$mdiEye","color":"primary"}},on))]}}],null,false,3743905013)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.visibleByClient'))}})])],1):_vm._e(),_c('div',{staticClass:"answer_validation"},[(_vm.questionToValided)?_c('WorkflowAnswerValidation',{attrs:{"question":_vm.questionToValided,"status-id":_vm.activeStatus.id},on:{"updateSubmiting":function($event){_vm.isSubmittingValidation = $event}}}):_vm._e()],1)],1)]},proxy:true}])},[_c('form',{staticClass:"modal_content",on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},_vm._l((_vm.questionsCategorised),function(category,index){return _c('div',{key:("category" + index)},[_c('div',{staticClass:"input-fields"},_vm._l((category.questions),function(question,i){return _c('div',{key:("wqf" + i + (question.id)),class:{
                  inputs: !question.typeQuestion.includes('DATE', 'DATETIME'),
                }},[(
                    question.answer &&
                    question.answer.extractedData &&
                    _vm.isSiretSiren(question)
                  )?[_c('WorkflowQuestionField',{attrs:{"focus":_vm.activeQuestions.length === 1,"question":question,"is-workflow-question":"","need-label":_vm.questionsCategorised.length > 1},on:{"set-initial-file":function (index) { return (_vm.initialFile = index); },"formValidityChange":function ($event) {
                        _vm.onFormValidityChange(i, $event);
                      }},model:{value:(_vm.form[("question" + (question.id))]),callback:function ($$v) {_vm.$set(_vm.form, ("question" + (question.id)), $$v)},expression:"form[`question${question.id}`]"}}),_c('WorkflowExtractedDataFields',{attrs:{"extracted-data":question.answer.extractedData,"question":question},on:{"formValidityChange":function ($event) {
                        _vm.onFormValidityChange(i, $event);
                      }},model:{value:(_vm.form[("question" + (question.id))]),callback:function ($$v) {_vm.$set(_vm.form, ("question" + (question.id)), $$v)},expression:"form[`question${question.id}`]"}})]:(question.answer && question.answer.extractedData)?[_c('WorkflowWezideeQuestionField',{attrs:{"question":question},on:{"formValidityChange":function ($event) {
                        _vm.onFormValidityChange(i, $event);
                      },"close":_vm.reset},model:{value:(_vm.form[("question" + (question.id))]),callback:function ($$v) {_vm.$set(_vm.form, ("question" + (question.id)), $$v)},expression:"form[`question${question.id}`]"}})]:[_c('WorkflowQuestionField',{attrs:{"focus":_vm.activeQuestions.length === 1,"question":question,"is-workflow-question":"","need-label":_vm.questionsCategorised.length > 1},on:{"set-initial-file":function (index) { return (_vm.initialFile = index); },"formValidityChange":function ($event) {
                        _vm.onFormValidityChange(i, $event);
                      }},model:{value:(_vm.form[("question" + (question.id))]),callback:function ($$v) {_vm.$set(_vm.form, ("question" + (question.id)), $$v)},expression:"form[`question${question.id}`]"}})]],2)}),0)])}),0),_c('div',{staticClass:"modal_footer"},[_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080","disabled":_vm.isSubmittingValidation},on:{"click":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"type":"primary","loading":_vm.isSubmitting,"disabled":_vm.isArchivedFolder || _vm.isSubmittingValidation},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }