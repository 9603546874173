var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer_item_container",class:{
    container_secondary: _vm.isPending || _vm.isRefused,
  }},[_c('FolderOfferItemHeader',{attrs:{"offer":_vm.offer,"offer-clients":_vm.offerClients,"is-pending":_vm.isPending,"is-refused":_vm.isRefused,"expanded":_vm.expanded,"is-spam":_vm.isSpam},on:{"expandChange":function($event){return _vm.changeExpandStatus()}}}),(_vm.expanded)?[_c('div',{class:_vm.getHeaderClass},[_c('div',{staticClass:"separator"}),(
          _vm.offer.offerType === _vm.OFFER_TYPES.SIGNATURE &&
          _vm.offer.status == 'PENDING'
        )?_c('div',{staticClass:"btn-cancel"},[_c('BaseButton',{staticClass:"ml-auto",attrs:{"color":"secondary","icon":"$mdiCloseCircleOutline"},on:{"click":function($event){return _vm.openCancelDialog(_vm.offer)}}},[_vm._v(" Annuler l’offre en cours ")])],1):_vm._e(),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"offer_details"},[(_vm.offerClients.length)?_c('div',{staticClass:"offer_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('utils.contact', _vm.offerClients.length))}}),_c('div',{staticClass:"content"},[(_vm.offer.procedure && _vm.offer.procedure.sequentialSignatures)?_c('div',{staticClass:"simple_alert",domProps:{"textContent":_vm._s(_vm.$t('folder.signatureOffer'))}}):_vm._e(),_c('BaseDataTable',{staticClass:"contact_list",attrs:{"headers":_vm.OFFER_CLIENT_HEADER,"items":_vm.offerClients,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',{staticClass:"contact_item"},[_c('td',[(item.lastMailStatus)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"email_status"},'div',attrs,false),on),[_c('BaseIcon',{staticClass:"email_status_icon",class:{
                                danger:
                                  item.lastMailStatus.status == 'ERROR' ||
                                  item.lastMailStatus.status == 'SPAM',
                              },attrs:{"icon":item.lastMailStatus.icon}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(item.lastMailStatus.label))}})]):_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(' - ')}})],1),_c('td',[_c('div',{staticClass:"d-flex align-center"},[(
                            _vm.offer.procedure &&
                            _vm.offer.procedure.sequentialSignatures
                          )?_c('div',{staticClass:"index",class:item.chipsClass,domProps:{"textContent":_vm._s(item.order)}}):_vm._e(),_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.email)}})],1)]),_c('td',[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.fullName ? item.fullName : ' - ')}})],1),_c('td',[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.phoneNumber ? item.phoneNumber : ' - ')}})],1),_c('td',[_c('div',{staticClass:"chips",class:item.chipsClass,domProps:{"textContent":_vm._s(item.statusLabel)}})]),_c('td',{staticClass:"text-center"},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(
                          item.reminders.length - 1 > 0
                            ? item.reminders.length - 1
                            : 0
                        )}})],1),_c('td',[(
                          item.status == 'PENDING' ||
                          (_vm.offer.publishDate && _vm.isPublished)
                        )?_c('div',[_c('button',{staticClass:"relaunch_btn",class:{ relaunched: item.reminders.length > 1 },on:{"click":function($event){return _vm.openRemindDialog(item)}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiEmailOutline"}}),_c('span',{domProps:{"textContent":_vm._s(
                              item.reminders.length > 1
                                ? _vm.$t('utils.relaunchAgain')
                                : _vm.$t('utils.relaunch')
                            )}})],1)]):_c('div',{domProps:{"textContent":_vm._s(' - ')}})])])]}}],null,false,3085777950)})],1)]):_vm._e(),(_vm.offer.files.length)?_c('div',{staticClass:"offer_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('utils.document', _vm.offer.files.length))}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"documents_card"},[_c('div',{staticClass:"offer_files"},[(
                      _vm.offer.offerType !== _vm.OFFER_TYPES.SIGNATURE ||
                      (_vm.offer && _vm.offer.status !== 'ACCEPT')
                    )?_vm._l((_vm.offer.files),function(file,i){return _c('div',{key:("f-" + i),staticClass:"offer_file_item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.open(file.offerFile)}}},[_c('div',{staticClass:"file_name",domProps:{"textContent":_vm._s(file.name)}}),_c('div',{staticClass:"file"},[(file.offerFile.includes('.pdf'))?_c('BasePdfViewer',{attrs:{"pdf":file.offerFile,"page":1}}):(
                            ['.docx', '.doc', '.pptx'].some(function (v) { return file.offerFile.includes(v); }
                            )
                          )?_c('base-icon',{attrs:{"icon":"$mdiFileDocument","color":"primary"}}):_c('BaseImage',{attrs:{"src":file.offerFile}})],1),_c('v-btn',{staticClass:"down_btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(file.offerFile, file.name)}}},[_c('BaseIcon',{attrs:{"icon":"$mdiDownload"}})],1)],1)}):_vm._l((_vm.offer.signedFiles),function(file,i){return _c('div',{key:("sf-" + i),staticClass:"offer_file_item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.open(file.signedPdf)}}},[_c('div',{staticClass:"file_name",domProps:{"textContent":_vm._s(file.name)}}),_c('div',{staticClass:"file"},[_c('BasePdfViewer',{attrs:{"pdf":file.signedPdf,"page":1}})],1),_c('v-btn',{staticClass:"down_btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(file.signedPdf, file.name)}}},[_c('BaseIcon',{attrs:{"icon":"$mdiDownload"}})],1)],1)})],2)])])]):_vm._e(),(
              _vm.offer.offerType === _vm.OFFER_TYPES.SIGNATURE &&
              _vm.offerClients.some(function (c) { return c.member && c.member.proof; })
            )?_c('div',{staticClass:"offer_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('folder.proofOfSignature'))}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"documents_card"},[(_vm.offerClients.length)?_c('div',{staticClass:"offer_files"},[_vm._l((_vm.offerClients),function(client,i){return [(client.member.proof)?_c('div',{key:("cf-" + i),staticClass:"offer_file_item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.open(client.member.proof)}}},[_c('div',{staticClass:"file_name",domProps:{"textContent":_vm._s(client.fullName)}}),_c('div',{staticClass:"file"},[_c('BasePdfViewer',{attrs:{"pdf":client.member.proof,"page":1}})],1),_c('v-btn',{staticClass:"down_btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(client.member.proof, client.fullName)}}},[_c('BaseIcon',{attrs:{"icon":"$mdiDownload"}})],1)],1):_vm._e()]})],2):_vm._e()])])]):_vm._e(),(_vm.offer.description)?_c('div',{staticClass:"offer_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('utils.offerDescription'))}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"desc_content"},[_c('BaseMarkdownViewer',{attrs:{"content":_vm.offer.description,"is-text-caption":""}})],1)])]):_vm._e(),_c('div',{staticClass:"offer_detail_item"},[[(_vm.isRefused)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"title title__primary",domProps:{"textContent":_vm._s(_vm.$t('utils.refusedMotif'))}}),_c('div',{staticClass:"refus__container"},_vm._l((_vm.offer.clients.filter(
                      function (client) { return client.status === 'DECLINE'; }
                    )),function(client){return _c('div',{key:client.id,staticClass:"desc_content"},[_c('span',{domProps:{"textContent":_vm._s('Par ')}}),_c('span',{staticClass:"email"},[_vm._v(_vm._s(client.email))]),_c('span',{domProps:{"textContent":_vm._s(' |')}}),_c('span',[_vm._v(" "+_vm._s(_vm.getDate(client.dateCreated)))]),_c('p',[_vm._v(_vm._s(client.reasonDecline))])])}),0)]):_vm._e()]],2)])])])]:_vm._e(),_c('FolderOfferRemindDialog',{attrs:{"offer":_vm.offer,"offer-client":_vm.selectedOfferClient,"dialog-state":_vm.remindDialogState,"organization-id":_vm.organizationId,"folder-id":_vm.folderId},on:{"close":function($event){_vm.remindDialogState = false}}}),(_vm.selectedOfferClient && _vm.selectedOfferClient.procedure)?_c('FolderOfferCancelDialog',{attrs:{"dialog-state":_vm.offerCancelDialogState,"organization-id":_vm.organizationId,"procedure":_vm.selectedOfferClient.procedure},on:{"close":function($event){_vm.offerCancelDialogState = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }