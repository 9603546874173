<template>
  <!-- QUAND LE COSMOFORM EST FERMER OU NON EXPIRER ON LE COLORIE EN GRIS -->
  <div
    class="record_item_container"
    :class="{
      container_secondary: !cosmoformDetail.isExpired || closedCosmoform,
    }"
  >
    <FolderCosmoformItemHeader
      :cosmoform-detail="cosmoformDetail"
      :expanded="expanded"
      :is-spam="isSpam"
      @expandChange="changeExpandStatus()"
    />
    <template v-if="expanded">
      <div :class="isSpam ? ' item_body is_spam' : 'item_body'">
        <div v-if="description" class="email">
          <div class="title" v-text="'Email envoyé'" />
          <div class="content">
            <div class="desc_content">
              <BaseMarkdownViewer :content="description" is-text-caption />
            </div>
          </div>
        </div>
        <div class="cosmoform_detail_container">
          <v-tabs v-model="activeTab" class="tab_header">
            <v-tab
              v-for="group in groupedCosmoforms"
              :key="`ctab-${group.categoryId + 1}`"
              class="tab_header_item"
            >
              {{ group.categoryName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <!-- ALL QUESTIONS -->
            <v-tab-item>
              <div class="tab_item">
                <div
                  v-for="(group, index) in groupedCosmoforms.filter(
                    (c) => c.cosmoforms.length > 0
                  )"
                  :key="`all-${index}`"
                  class="grouped_questions"
                >
                  <div
                    v-if="group.categoryId"
                    class="question_category"
                    v-text="group.categoryName"
                  />
                  <div class="cosmoform_grid">
                    <FolderPreviewQuestion
                      v-for="(cosmoform, ind) in [...group.cosmoforms].sort(
                        (a, b) => a.order - b.order
                      )"
                      :key="`q-${ind}${group.categoryId + 1}`"
                      :editable="false"
                      :disabled="!cosmoform.answer"
                      :selectable="false"
                      :question="{
                        ...cosmoform.question,
                        answer: cosmoform.answer,
                      }"
                      @questionClick="openQuestionValidation(cosmoform)"
                    />
                  </div>
                </div>
              </div>
            </v-tab-item>
            <!-- OTHER QUESTIONS -->
            <v-tab-item
              v-for="group in groupedCosmoforms.filter((gsq) => gsq.categoryId)"
              :key="`gitem-${group.categoryId + 1}`"
            >
              <div class="tab_item">
                <div class="grouped_questions">
                  <div class="cosmoform_grid">
                    <FolderPreviewQuestion
                      v-for="(cosmoform, ind) in [...group.cosmoforms].sort(
                        (a, b) => a.order - b.order
                      )"
                      :key="`cq-${ind}${group.categoryId + 1}`"
                      :editable="false"
                      :selectable="false"
                      :question="{
                        ...cosmoform.question,
                        answer: cosmoform.answer,
                      }"
                      :disabled="!cosmoform.answer"
                      :class="!cosmoform.answer ? 'disable' : ''"
                      @questionClick="openQuestionValidation(cosmoform)"
                    />
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div class="separator" />
        <div class="relaunch_container">
          <div
            v-if="!cosmoformDetail.isExpired || closedCosmoform"
            class="relaunch_card"
          >
            <button
              class="relaunch_btn"
              :class="{ relaunched_btn: relaunchNumber > 0 }"
              :disabled="isArchivedFolder || closedCosmoform"
              @click="openRelaunchModal()"
            >
              <BaseIcon class="icon" icon="$mdiEmailOutline" />
              <span v-text="$t('utils.relaunch')" />
            </button>
            <div
              class="relaunch_detail"
              v-text="
                $t('utils.relaunchNumber', {
                  value: relaunchNumber,
                })
              "
            />
          </div>
        </div>
      </div>
    </template>
    <folder-display-cosmo-form-answer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FolderCosmoformItemHeader from "./FolderCosmoformItemHeader";
import FolderPreviewQuestion from "@/modules/Folder/Components/FolderPreviewQuestion";
import FolderDisplayCosmoFormAnswer from "../FolderDisplayCosmoFormAnswer.vue";

export default {
  name: "FolderCosmoformItem",
  components: {
    FolderCosmoformItemHeader,
    FolderPreviewQuestion,
    FolderDisplayCosmoFormAnswer,
  },
  props: {
    cosmoformDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    relaunchNumber() {
      return this.cosmoformDetail.reminders.length - 1;
    },
    closedCosmoform() {
      return this.cosmoformDetail.state == "CLOSED";
    },
    groupedCosmoforms() {
      const groupedCosmoforms = [];
      const withoutCategoryGroup = {
        categoryId: null,
        categoryName: this.$t("utils.all"),
        cosmoforms: [],
      };
      this.cosmoformDetail.question.forEach((cosmoform) => {
        if (cosmoform.question.category && cosmoform.question.category.id) {
          const categoryIndex = groupedCosmoforms.findIndex(
            (groupe) => groupe.categoryId === cosmoform.question.category.id
          );
          if (categoryIndex !== -1) {
            groupedCosmoforms[categoryIndex].cosmoforms.push(cosmoform);
          } else {
            groupedCosmoforms.push({
              categoryId: cosmoform.question.category.id,
              categoryName: cosmoform.question.category.name,
              categoryOrder: cosmoform.question.category.order,
              cosmoforms: [cosmoform],
            });
          }
        } else {
          withoutCategoryGroup.cosmoforms.push(cosmoform);
        }
      });
      groupedCosmoforms.unshift(withoutCategoryGroup);
      return groupedCosmoforms
        .map((group) => {
          group.cosmoforms.sort((a, b) => a.question.order - b.question.order);
          return group;
        })
        .sort((a, b) => a.categoryOrder - b.categoryOrder);
    },
    isSpam() {
      if (!this.cosmoformDetail.reminders?.length) return null;
      const { status } = this.cosmoformDetail.reminders[0].transactionalEmail;
      return status === "SPAM";
    },
    description() {
      return this.cosmoformDetail?.description;
    },
  },

  methods: {
    changeExpandStatus() {
      this.expanded = !this.expanded;
    },
    openRelaunchModal() {
      this.$store.commit(
        "folder/SET_COSMOFORM_TO_RELAUNCH",
        this.cosmoformDetail
      );
      this.$store.commit("folder/SET_RELAUNCH_COSMOFORM_DIALOG_OPEN", true);
    },
    openQuestionValidation(cosmoform) {
      this.$store.commit("folder/setActiveCosmoform", cosmoform);
      this.$store.commit("folder/setIsCosmoformDialogDisplayOpen", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.record_item_container {
  background: #fff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;
  &.container_secondary {
    background: #f5f5f5;
  }
  // BODY
  .item_body {
    border-left: 32px solid #b6bdff;
    &.is_spam {
      border-left: 32px solid #ffc084;
    }
    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .relaunch_container {
      padding: 24px 32px;
      .relaunch_card {
        background: #fafafc;
        border-radius: 5px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .relaunch_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          background: #f4f7ff;
          border-radius: 5px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #2536cc;
          & > .icon {
            &::v-deep {
              .v-icon {
                width: 16px;
                height: 16px;
                color: #2536cc;
              }
            }
          }
          & > * {
            margin: 0 4px;
          }
        }
        .relaunched_btn {
          color: #ff6b00;
          background: #fff3f0;
          & > .icon {
            &::v-deep {
              .v-icon {
                color: #ff6b00;
              }
            }
          }
        }
        .relaunch_detail {
          margin-top: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
        }
      }
    }
    .cosmoform_detail_container {
      padding: 24px 32px;
      .tab_header {
        .tab_header_item {
          margin-inline: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .tab_item {
        padding-top: 24px;
        .grouped_questions {
          background: white;
          width: 100%;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 8px;
          }
          .question_category {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #353542;
            margin-bottom: 16px;
          }
          .cosmoform_grid {
            display: grid;
            padding: 8px;
            padding-bottom: 4px;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            grid-template-columns: 100%;
            max-height: 248px;
            overflow-y: auto;
            overflow-x: hidden;
            @media (min-width: 1200px) {
              grid-template-columns: repeat(2, calc(calc(100% - 16px) / 2));
            }
            @media (min-width: 1400px) {
              grid-template-columns: repeat(3, calc(calc(100% - 32px) / 3));
            }
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-track {
              background: #fff;
              border-radius: 3px;
              border: 1px solid #eeeef7;
            }

            &::-webkit-scrollbar-thumb {
              background: #b6bdff;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
  .email {
    padding: 32px;
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #353542;
    }
    .simple_alert {
      padding: 14px;
      margin-bottom: 16px;
      background: #fafafc;
      border-radius: 5px;
      border: 0.5px solid #cfcfdc;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #353542;
    }
    .content {
      margin-top: 16px;
    }
    .contact_item {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #353542;
      .index {
        padding: 4px 8px;
        width: 22px;
        height: 23px;
        background: rgba(155, 189, 255, 0.22);
        border-radius: 5px;
        margin-right: 4px;
        &.chips_simple {
          background: none;
        }
      }
      & > * {
        margin-right: 35px;
      }
      &:last-child {
        margin: 0;
      }
      .chips {
        background: #eeeef7;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #707080;
        padding: 4px 8px;
        border-radius: 24px;
        &.chips_success {
          background: #f0ffd6;
          color: #18a300;
        }
        &.chips_danger {
          background: #fef9fa;
          color: #ff5267;
        }
        &.chips_canceled {
          background: #eeeef7;
          color: #707080;
        }
        &.chips_active {
          background: #e9f1ff;
        }
        &.chips_simple {
          background: #eeeef7;
        }
      }
    }
    .desc_content {
      background: #ffffff;
      padding: 24px;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #353542;
      border: 0.5px solid #cfcfdc;
    }
    .documents_card {
      background: #fff;
      border-radius: 5px;
      padding: 12px;
      border: 0.5px solid #cfcfdc;
      .offer_files {
        padding: 12px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        grid-template-rows: auto;
        max-height: 248px;
        overflow: auto;
        @media (min-width: 1066px) {
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: repeat(2, calc(50% - 8px));
        }
        @media (min-width: 1400px) {
          grid-column-gap: calc(4% / 2);
          grid-row-gap: 16px;
          grid-template-columns: repeat(3, 32%);
        }
        @media (min-width: 1600px) {
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: repeat(4, calc(25% - 16px));
        }
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 3px;
          border: 1px solid #eeeef7;
        }

        &::-webkit-scrollbar-thumb {
          background: #b6bdff;
          border-radius: 3px;
        }
        .offer_file_item {
          width: 100%;
          cursor: pointer;
          position: relative;
          .file_name {
            padding: 0 8px;
            width: 100%;
            font-size: 10px;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
          }
          .file {
            border: 1px solid #e5e5e5;
            height: auto;
            overflow: hidden;
            width: 100%;
            height: 200px;
            & > * {
              height: 100%;
              width: 100%;
              object-fit: cover;
              margin: 0;
            }
          }
          .down_btn {
            background: #f4f7ff;
            position: absolute;
            right: 12px;
            bottom: 12px;
            box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
              0px 10px 10px rgba(46, 45, 193, 0.06);
            &::v-deep {
              .v-icon {
                width: 20px;
                height: 20px;
                color: #2536cc !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
