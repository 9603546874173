import WorkflowAPI from "./workflow.api";
import { CosmoformTemplate } from "@/core/Models/Cosmoform";
//#region Collaborators > Workflow - Service
export function getOrganizationCollaborators(organizationId, page) {
  return WorkflowAPI.getOrganizationCollaborators(organizationId, page);
}

export function getCollaboratorAssignedToStatus(
  organizationId,
  workflowId,
  statusId
) {
  return WorkflowAPI.fetchAssignedCollaboratorStatus(
    organizationId,
    workflowId,
    statusId
  );
}

//#endregion
//#region Organization > Workflow - Service
export function fetchOrganizationWorkflows(organizationId, page) {
  return WorkflowAPI.getOrganizationWorkflows(organizationId, page);
}
export function postOrganisationWorkflowStatusMove(payload) {
  return WorkflowAPI.postOrganisationWorkflowStatusMove(payload);
}

export function fetchOrganizationWorkflow(organizationId, workflowId) {
  return WorkflowAPI.getOrganizationWorkflow(organizationId, workflowId);
}

export function postOrganizationWorkflow(organizationId, payload) {
  return WorkflowAPI.postOrganizationWorkflow(organizationId, payload);
}

export function patchOrganizationWorkflow(organizationId, workflowId, data) {
  let payload = {};
  if (data.name !== undefined) {
    payload.name = data.name;
  }
  if (data.primary_status !== undefined) {
    payload.primary_status = data.primary_status;
  }
  return WorkflowAPI.patchOrganizationWorkflow(
    organizationId,
    workflowId,
    payload
  );
}

export function deleteOrganizationWorkflow(organizationId, workflowId) {
  return WorkflowAPI.deleteOrganizationWorkflow(organizationId, workflowId);
}

//#endregion
//#region Organization > Workflow > Status > Collaborator - Service
export function addCollaboratorStatus(
  collaboratorId,
  organizationId,
  statusId,
  workflowId
) {
  return WorkflowAPI.addCollaboratorToStatus(
    collaboratorId,
    organizationId,
    statusId,
    workflowId
  );
}

export function delCollaboratorStatus(
  organizationId,
  workflowId,
  statusId,
  assignStatusId
) {
  return WorkflowAPI.delCollaboratorToStatus(
    organizationId,
    workflowId,
    statusId,
    assignStatusId
  );
}

//#endregion
//#region Organization > Workflow > Status - Service
export function postOrganizationWorkflowStatus(
  organizationId,
  workflowId,
  payload
) {
  return WorkflowAPI.postOrganizationWorkflowStatus(
    organizationId,
    workflowId,
    payload
  );
}

export function patchOrganizationWorkflowStatus(
  organizationId,
  workflowId,
  statusId,
  data
) {
  let payload = {};
  if (data.name !== undefined) {
    payload.name = data.name;
  }
  return WorkflowAPI.patchOrganizationWorkflowStatus(
    organizationId,
    workflowId,
    statusId,
    payload
  );
}

export function deleteOrganizationWorkflowStatus(
  organizationId,
  workflowId,
  statusId
) {
  return WorkflowAPI.deleteOrganizationWorkflowStatus(
    organizationId,
    workflowId,
    statusId
  );
}

//#endregion
//#region Organization > Workflow > Status > Question - Service
export function fetchorganizationWorkflowStatusQuestions(
  organizationId,
  workflowId,
  statusId,
  page
) {
  return WorkflowAPI.fetchorganizationWorkflowStatusQuestions(
    organizationId,
    workflowId,
    statusId,
    page
  );
}

export function postOrganizationWorkflowStatusQuestion(
  organizationId,
  workflowId,
  statusId,
  payload
) {
  let formData = new FormData();
  formData.append("name", payload.name);
  formData.append("label", payload.label);
  formData.append("read_only", payload.readOnly);
  formData.append("has_free_option", payload.has_free_option);
  payload.typeQuestion
    ? formData.append("type_question", payload.typeQuestion)
    : null;
  payload.required ? formData.append("required", payload.required) : null;
  payload.helpText ? formData.append("help_text", payload.helpText) : null;
  payload.helpFile ? formData.append("help_file", payload.helpFile) : null;
  payload.category ? formData.append("category", payload.category) : null;

  return new Promise((resolve, reject) => {
    WorkflowAPI.postOrganizationWorkflowStatusQuestion(
      organizationId,
      workflowId,
      statusId,
      formData
    )
      .then((r) => {
        if (payload.choices && payload.choices.length) {
          let promises = [];
          payload.choices.forEach((choice) => {
            promises.push(
              WorkflowAPI.postOrganizationWorkflowStatusQuestionOption(
                organizationId,
                workflowId,
                statusId,
                r.data.id,
                { name: choice.name, order: choice.order }
              )
            );
          });
          Promise.all(promises)
            .then((responses) => {
              responses.forEach((response) => {
                r.data.choices.push(response.data);
              });
              resolve(r.data);
            })
            .catch((err) => reject(err));
        } else resolve(r.data);
      })
      .catch((e) => reject(e));
  });
}

export async function postOrganisationWorkflowStatusDatatypeQuestion({
  organizationId,
  workflowId,
  statusId,
  payload,
}) {
  let formData = new FormData();
  formData.append("name", payload.name);
  formData.append("label", payload.label);
  formData.append("read_only", payload.readOnly);
  payload.datatype ? formData.append("datatype", payload.datatype) : null;
  payload.required ? formData.append("required", payload.required) : null;
  payload.helpText ? formData.append("help_text", payload.helpText) : null;
  payload.helpFile ? formData.append("help_file", payload.helpFile) : null;
  payload.category ? formData.append("category", payload.category) : null;

  const res = await WorkflowAPI.postOrganisationWorkflowStatusDatatypeQuestion({
    organizationId,
    workflowId,
    statusId,
    payload: formData,
  });
  return res.data;
}

export function updateOrganizationWorkflowStatusQuestion(
  organizationId,
  workflowId,
  statusId,
  questionId,
  payload,
  deletedOptionsIds
) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    if (payload.name !== undefined) formData.append("name", payload.name);
    if (payload.label !== undefined) formData.append("label", payload.label);
    if (payload.order) formData.append("order", payload.order);
    if (payload.readOnly !== undefined)
      formData.append("read_only", payload.readOnly);
    if (payload.typeQuestion !== undefined)
      formData.append("type_question", payload.typeQuestion);
    if (payload.required !== undefined)
      formData.append("required", payload.required);
    if (payload.helpText !== undefined)
      formData.append("help_text", payload.helpText);
    if (payload.helpFile !== undefined)
      formData.append("help_file", payload.helpFile ? payload.helpFile : "");
    if (payload.has_free_option !== undefined)
      formData.append("has_free_option", payload.has_free_option);
    formData.append("category", payload.category ? payload.category : "");

    payload.datatype ? formData.append("datatype", payload.datatype) : null;

    WorkflowAPI.patchOrganizationWorkflowStatusQuestion(
      organizationId,
      workflowId,
      statusId,
      questionId,
      formData
    )
      .then((res) => {
        const editedQuestion = res.data;
        if (payload.choices && payload.choices.length > 0) {
          /* Delete options */
          deletedOptionsIds.forEach(async (id) => {
            await WorkflowAPI.deleteOrganizationWorkflowStatusQuestionOption(
              organizationId,
              workflowId,
              statusId,
              questionId,
              id
            );
          });
          /* Edit question options */
          payload.choices
            .filter((choice) => choice.id)
            .forEach(async (choice) => {
              await WorkflowAPI.patchOrganizationWorkflowStatusQuestionOption(
                organizationId,
                workflowId,
                statusId,
                questionId,
                choice.id,
                { name: choice.name, order: choice.order }
              ).then((choice) =>
                editedQuestion.choices
                  .filter((c) => c.id != choice.data.id)
                  .push(choice.data)
              );
            });
          /* create question options */
          payload.choices
            .filter((choice) => !choice.id)
            .forEach(async (choice) => {
              await WorkflowAPI.postOrganizationWorkflowStatusQuestionOption(
                organizationId,
                workflowId,
                statusId,
                questionId,
                { name: choice.name, order: choice.order }
              ).then((choice) => editedQuestion.choices.push(choice.data));
            });
        }
        resolve(editedQuestion);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function deleteOrganizationWorkflowStatusQuestion(
  organizationId,
  workflowId,
  statusId,
  questionId
) {
  return WorkflowAPI.deleteOrganizationWorkflowStatusQuestion(
    organizationId,
    workflowId,
    statusId,
    questionId
  );
}

//#endregion
//#region Organization > Workflow > Status > Question > Answer - Service
export function postOrganizationFolderStatusQuestionAnswer({
  organizationId,
  folderId,
  statusId,
  questionId,
  payload,
}) {
  return WorkflowAPI.postOrganizationFolderStatusQuestionAnswer({
    organizationId,
    folderId,
    statusId,
    questionId,
    payload,
  });
}

export function patchOrganizationFolderStatusQuestionAnswer({
  organizationId,
  folderId,
  statusId,
  questionId,
  answerId,
  payload,
}) {
  return WorkflowAPI.patchOrganizationFolderStatusQuestionAnswer({
    organizationId,
    folderId,
    statusId,
    questionId,
    answerId,
    payload,
  });
}

export function deleteOrganizationFolderStatusQuestionAnswer(
  organizationId,
  folderId,
  statusId,
  questionId,
  answerId
) {
  return WorkflowAPI.deleteOrganizationFolderStatusQuestionAnswer(
    organizationId,
    folderId,
    statusId,
    questionId,
    answerId
  );
}

//#endregion
//#region Organization > Workflow > Status > Question > Option - Service
export function fetchOrganizationWorkflowStatusQuestionOptions(
  organizationId,
  workflowId,
  statusId,
  questionId
) {
  return WorkflowAPI.getOrganizationWorkflowStatusQuestionOptions(
    organizationId,
    workflowId,
    statusId,
    questionId
  ).then((res) => res.data.results);
}

//#endregion
//#region Organization > Workflow > Status > Action - Service
export function fetchOrganizationWorkflowStatusActions({
  organizationId,
  workflowId,
  statusId,
  page,
}) {
  return WorkflowAPI.getOrganizationWorkflowStatusActions({
    organizationId,
    workflowId,
    statusId,
    page,
  });
}
export function postOrganizationWorkflowStatusAction({
  organizationId,
  workflowId,
  statusId,
  actionId,
}) {
  return WorkflowAPI.postOrganizationWorkflowStatusAction({
    organizationId,
    workflowId,
    statusId,
    actionId,
  });
}

export function deleteOrganizationWorkflowStatusAction({
  organizationId,
  workflowId,
  statusId,
  actionId,
}) {
  return WorkflowAPI.deleteOrganizationWorkflowStatusAction({
    organizationId,
    workflowId,
    statusId,
    actionId,
  });
}

//#endregion
//#region Organization > Workflow > Status > TargetStatus - Service
export function createOrganizationWorkflowStatusTargetStatus(
  organizationId,
  workflowId,
  statusId,
  payload
) {
  return WorkflowAPI.postOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    payload
  );
}

export function fetchOrganizationWorkflowStatusTargetStatuses(
  organizationId,
  workflowId,
  statusId
) {
  return WorkflowAPI.getOrganizationWorkflowStatusTargetStatuses(
    organizationId,
    workflowId,
    statusId
  );
}

export function updateOrganizationWorkflowStatusTargetStatus(
  organizationId,
  workflowId,
  statusId,
  targetStatusId,
  payload
) {
  return WorkflowAPI.patchOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    targetStatusId,
    payload
  );
}

export function deleteOrganizationWorkflowStatusTargetStatus(
  organizationId,
  workflowId,
  statusId,
  targetStatusId
) {
  return WorkflowAPI.deleteOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    targetStatusId
  );
}
export async function createOrganizationFolderCosmoform({
  organizationId,
  folderId,
  payload,
}) {
  return await WorkflowAPI.postOrganizationFolderCosmoformSubmit({
    organizationId,
    folderId,
    payload,
  });
}

export async function getOrganizationCosmoformTemplate({ organizationId }) {
  let res = await WorkflowAPI.getOrganizationCosmoformTemplate({
    organizationId,
  });
  return res.data.results.map((ct) => new CosmoformTemplate(ct));
}

export async function getOrganizationCosmoformTemplateDetail({
  organizationId,
  cosmoformTemplateId,
}) {
  let res = await WorkflowAPI.getOrganizationCosmoformTemplateDetail({
    organizationId,
    cosmoformTemplateId,
  });
  return res.data;
}
