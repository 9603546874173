<template>
  <div class="input_group">
    <v-autocomplete
      v-model="selectedCategory"
      :items="filteredQuestionCategories"
      :filter="filter"
      :placeholder="placeHolder"
      persistent-placeholder
      hide-selected
      dense
      item-value="id"
      :hide-no-data="!queryText || !!newCategoryNameError"
      :search-input.sync="queryText"
      :loading="isFetchingCategories || isAdding"
      :error-messages="newCategoryNameError"
      small-chips
      outlined
      :menu-props="{ offsetY: true }"
      :active="true"
      @change="selectedCategoryUpdated()"
      @keypress.enter="addCategory()"
      @focus="onFocus()"
      @focusout="onFocus()"
    >
      <template #no-data>
        <div class="no_data_section">
          <div class="label" v-text="`${$t('utils.create')} : `" />
          <div class="value" v-text="queryText" />
          <BaseButton
            :disabled="!!newCategoryNameError"
            type="primary"
            small
            :loading="isAdding"
            @click.stop.prevent="addCategory()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </template>
      <template #selection="{ item }">
        <v-chip color="primary" label small>
          <span class="pr-2">
            {{ item.name }}
          </span>
          <v-icon small @click="deselectCategory()">
            {{ "$mdiClose" }}
          </v-icon>
        </v-chip>
      </template>
      <template #item="{ item }">
        <div class="caterogy_item">
          <template>
            <template v-if="editingCategory && editingCategory.id === item.id">
              <v-text-field
                v-model="editCategoryName"
                autofocus
                flat
                background-color="transparent"
                hide-details
                solo
                @click.stop
                @keydown.stop.enter="editCategory(item)"
              />
              <BaseButton
                :disabled="!editCategoryName"
                type="primary"
                small
                :loading="editingCategory.id === item.id && isSubmiting"
                @click.stop.prevent="editCategory(item)"
              >
                {{ $t("utils.validate") }}
              </BaseButton>
            </template>
            <template v-else>
              <div>
                {{ item.name }}
              </div>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-list-item-action>
                    <v-btn icon v-bind="attrs" v-on="on">
                      <BaseIcon icon="$mdiDotsHorizontal" />
                    </v-btn>
                  </v-list-item-action>
                </template>
                <div class="menu_list">
                  <v-btn
                    class="menu_list_item"
                    block
                    text
                    @click="changeToEditMode(item)"
                  >
                    <BaseIcon
                      small
                      left
                      icon="$mdiPencilOutline"
                      color="primary"
                    />
                    <span class="label" v-text="$t('utils.edit')" />
                  </v-btn>
                  <v-btn
                    class="menu_list_item"
                    block
                    text
                    @click="removeCategory(item)"
                  >
                    <BaseIcon
                      small
                      left
                      icon="$mdiTrashCanOutline"
                      color="secondary"
                    />
                    <span class="label" v-text="$t('utils.delete')" />
                  </v-btn>
                </div>
              </v-menu>
            </template>
          </template>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";

export default {
  name: "WorkflowQuestionCategory",
  props: {
    value: {
      type: Number,
      default: null,
    },
    organizationId: {
      type: Number,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
    workflowId: {
      type: Number,
      required: true,
    },
    placeHolder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isFetchingCategories: false,
    selectedCategory: null,
    editCategoryName: null,
    editingCategory: null,
    isAdding: false,
    isSubmiting: false,
    queryText: "",
    statusCategories: [],
  }),
  computed: {
    filteredQuestionCategories() {
      return [...this.statusCategories].sort((a, b) => a.order - b.order);
    },
    newCategoryNameError() {
      if (!this.queryText) return "";
      if (this.queryText.trim().length > 35)
        return this.$t("category.nameTooLong");
      return "";
    },
  },
  watch: {
    value(val) {
      this.selectedCategory = val;
    },
    statusId: {
      handler() {
        this.fetchWorkflowStatusCategories();
      },
      immediate: true,
    },
  },
  methods: {
    onFocus() {
      this.$emit("onFocus");
    },
    async fetchWorkflowStatusCategories() {
      this.isFetchingCategories = true;
      this.statusCategories = [];
      if (!isNaN(this.statusId) && !isNaN(this.workflowId)) {
        this.statusCategories = (
          await WorkflowAPI.getWorkflowStatusCategories({
            organizationId: this.organizationId,
            workflowId: this.workflowId,
            statusId: this.statusId,
          })
        ).data?.results;
      }
      this.isFetchingCategories = false;
    },
    changeToEditMode(category = null) {
      this.editingCategory = category;
      this.editCategoryName = category ? category.name : null;
    },
    filter(item, queryText) {
      if (!queryText) return true;
      return (
        item.name &&
        item.name.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
    async addCategory() {
      if (!this.queryText || this.newCategoryNameError) return;
      this.isAdding = true;
      let newCategory = (
        await WorkflowAPI.postWorkflowStatusCategory({
          organizationId: this.organizationId,
          workflowId: this.workflowId,
          statusId: this.statusId,
          payload: {
            name: this.queryText,
            status: this.statusId,
            order: Math.max(
              1,
              ...this.statusCategories.map((c) => c.order + 1)
            ),
          },
        })
      ).data;
      this.statusCategories.push(newCategory);
      this.selectedCategory = newCategory.id;
      this.selectedCategoryUpdated();
      this.queryText = "";
      this.isAdding = false;
    },
    async editCategory(category) {
      this.isSubmiting = true;
      await WorkflowAPI.patchWorkflowStatusCategory({
        organizationId: this.organizationId,
        workflowId: this.workflowId,
        statusId: this.statusId,
        categoryId: category.id,
        payload: {
          name: this.editCategoryName,
        },
      });
      this.statusCategories.splice(
        this.statusCategories.findIndex((c) => c.id === category.id),
        1,
        {
          ...category,
          name: this.editCategoryName,
        }
      );
      this.isSubmiting = false;
      this.changeToEditMode();
    },
    async removeCategory(category) {
      this.isSubmiting = true;
      await WorkflowAPI.deleteWorkflowStatusCategory({
        organizationId: this.organizationId,
        workflowId: this.workflowId,
        statusId: this.statusId,
        categoryId: category.id,
      });
      this.statusCategories = this.statusCategories.filter(
        (c) => c.id != category.id
      );
      this.isSubmiting = false;
    },
    deselectCategory() {
      this.selectedCategory = null;
      this.selectedCategoryUpdated();
    },
    selectedCategoryUpdated() {
      this.$emit("input", this.selectedCategory ? this.selectedCategory : null);
    },
  },
};
</script>

<style lang="scss" scoped>
.input_group {
  .label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1em;
    color: #cfcfdc;
  }
  ::v-deep {
    .v-input__control .v-input__slot {
      & > fieldset {
        border: 1px solid #cfcfdc;
      }
    }
  }
}
.no_data_section {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  .value {
    margin: 0 8px;
    flex-grow: 1;
  }
}
.caterogy_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: center;
    &::v-deep {
      .label {
        font-size: 12px;
      }
    }
  }
}

.v-select--is-menu-active {
  ::v-deep .v-input__slot > fieldset {
    border: 2px solid #2536cc !important;
  }
}
</style>
