<template>
  <div>
    <BaseModal
      v-model="dialogState"
      :modal-title="$t('folder.askInformation')"
      @close="reset"
    >
      <template #modal>
        <div class="modal_content">
          <BaseSelect
            v-if="campaignList"
            v-model="formData.campaign"
            :items="campaignList"
            :label="`${$t('folder.askInformationForm.courseChoise')} *`"
            :error-messages="campaignErrors"
            item-text="name"
            item-value="id"
            solo
            :loading="isFetchingCampaign"
            need-selection
            content-class="campaign_select"
            @change="onSelectCampaign"
          >
            <template #selection="{ item }">
              <div
                class="d-flex align-items-center justify-space-between select-content"
              >
                <base-paragraph v-text="item.name" />
                <base-paragraph
                  v-if="item.course.anonymous"
                  v-text="'( Sans compte )'"
                />
              </div>
            </template>
          </BaseSelect>
          <v-combobox
            v-if="activeFolder"
            v-model="formData.email"
            :items="activeFolder.emails.map((e) => e.email)"
            class="email_input elevation-0"
            chips
            dense
            :disabled="emailDisabled"
            clearable
            :label="$t('folder.askInformationForm.email')"
            outlined
            :error-messages="emailErrors"
            @change="$v.formData.$touch()"
          >
            <template #selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                class="mail-chips"
                :input-value="selected"
                close
                :color="isError(item) ? 'red' : ''"
                :text-color="isError(item) ? 'white' : ''"
                :disabled="emailDisabled"
                @click="select"
                @click:close="formData.email = null"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <BaseMarkdownEditor
            v-model="formData.content"
            name="input-7-1"
            :label="$t('folder.askInformationForm.contentLabel')"
            :hint="$t('folder.askInformationForm.contentHint')"
            :with-link="false"
          />
        </div>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton
          type="primary"
          color="primary"
          :loading="isSubmiting"
          :disabled="$v.formData.$invalid"
          @click="submit()"
        >
          {{ $t("utils.sendInvitation") }}
        </BaseButton>
      </template>
    </BaseModal>
    <folder-confirm-assign-dialog
      :is-open="openConfirmModal"
      :confirm-email="formData.email"
      @on-validate="submit"
      @close="
        () => {
          openConfirmModal = false;
          isSubmiting = false;
        }
      "
    />
  </div>
</template>

<script>
import { fetchOrganizationCampaigns } from "../Services/folder.service";

import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import BaseMarkdownEditor from "../../../core/Components/UI/Markdown/BaseMarkdownEditor.vue";
import FolderConfirmAssignDialog from "./FolderConfirmAssignDialog.vue";

export default {
  name: "FolderAskInformationDialog",
  components: {
    BaseMarkdownEditor,
    FolderConfirmAssignDialog,
  },
  mixins: [validationMixin],
  data() {
    return {
      isFetchingCampaign: true,
      emailErrorIndexes: [],
      campaignList: [],
      formData: {
        email: null,
        campaign: null,
        content: "",
      },
      isSubmiting: false,
      openConfirmModal: false,
    };
  },
  validations: {
    formData: {
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      campaign: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      activeCampaignId: (state) => state.folder.activeCampaignId,
      dialogState: (state) => state.folder.modals.isAskInformationDialogOpen,
    }),
    emailErrors() {
      if (!this.$v.formData.email.$dirty) {
        return "";
      }
      if (!this.$v.formData.email.required) {
        return this.$t("folder.askInformationForm.validation.emailRequired");
      }
      if (!this.$v.formData.email.email) {
        return this.$t("folder.askInformationForm.validation.emailInvalid");
      }
      return "";
    },
    campaignErrors() {
      if (!this.$v.formData.campaign.$dirty) {
        return "";
      }
      if (!this.$v.formData.campaign.required) {
        return this.$t("folder.askInformationForm.validation.campaignRequired");
      }
      return "";
    },
    hasClient() {
      return !this.activeFolder?.client?.isTemporary;
    },
    emailDisabled() {
      const campaign = this.campaignList.find(
        (campaign) => campaign.id === this.formData.campaign
      );
      if (
        campaign?.course.anonymous ||
        !this.hasClient ||
        !this.formData.campaign
      )
        return false;
      return true;
    },
  },
  watch: {
    dialogState(newVal) {
      if (!newVal) {
        return;
      }
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      this.isFetchingCampaign = true;
      if (!this.campaignList.length) {
        await this.fetchCampaigns();
      }
      this.formData.campaign = this.activeCampaignId;
      this.isFetchingCampaign = false;

      if (this.activeCampaignId) return this.onSelectCampaign();
    },
    onSelectCampaign() {
      const selectedCampaign = this.campaignList.find(
        (el) => el.id == this.formData.campaign
      );
      this.formData.content = selectedCampaign?.defaultEmailContent;
      return (this.formData.email =
        this.hasClient && !selectedCampaign.course.anonymous
          ? this.activeFolder.client.email
          : null);
    },
    isError() {
      let error = false;
      if (!this.$v.formData.email.required || !this.$v.formData.email.email) {
        return (error = true);
      }
      return error;
    },
    async fetchCampaigns() {
      this.isFetchingCampaign = true;
      let page = 1;
      let campaignList = [];
      while (page) {
        const res = await fetchOrganizationCampaigns(
          this.$route.params.organizationId,
          page
        );
        campaignList = [...campaignList, ...res.data.results];
        page = res.data.next;
      }
      this.campaignList = campaignList;
      this.isFetchingCampaign = false;
    },
    reset() {
      this.formData = {
        email: null,
        campaign: null,
        content: "",
      };
      this.openConfirmModal = false;
      this.$v.$reset();
      this.$store.commit("folder/SET_ACTIVE_COMPAIGN_ID", null);
      this.$store.commit("folder/setAskInformationDialog", false);
    },
    async submit() {
      this.isSubmiting = true;
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) return;
      const campaign = this.campaignList.find(
        (campaign) => campaign.id === this.formData.campaign
      );
      if (
        !this.hasClient &&
        !this.openConfirmModal &&
        !campaign.course.anonymous
      ) {
        // if no client and not open yet and parcours anonyme => we ask for confirmation
        return (this.openConfirmModal = true);
      }
      const payload = {
        ...this.formData,
        email_content: this.formData.content ? this.formData.content : "",
      };
      try {
        await this.$store.dispatch("folder/inviteFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
          payload,
        });
        Promise.all([
          await this.$store.dispatch(
            "folder/getOrganizationOffersRecordFolder",
            {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
            }
          ),
          await this.$store.dispatch("folder/fetchActiveFolder", {
            organizationId: this.$route.params.organizationId,
            folderId: this.activeFolder.id,
          }),
        ]);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.askInformationForm.success"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.askInformationForm.error"),
          type: "ERROR",
        });
      }
      this.reset();
      this.isSubmiting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot .v-select__selections {
  margin: 0 !important;
}

::v-deep .email_input .v-input__icon--append {
  display: none;
}
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 16px 0 16px;
}
.modal_content {
  padding: 0px 16px;
}
.mail-chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}
.red {
  background: #fef9fa !important;
  color: #ff5267 !important;
  ::v-deep .v-chip__close {
    color: #ff5267;
  }
}
::v-deep .v-list-item--link {
  margin: 8px;
  &:hover {
    background: rgba(155, 189, 255, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }
}
::v-deep .v-list-item--active {
  background: rgba(155, 189, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
</style>
<style lang="scss">
.campaign_select {
  background: pink;
  .campaign_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .icon {
      margin-left: auto;
    }
  }
}
</style>
