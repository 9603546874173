var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_header",on:{"click":function($event){return _vm.changeExpandStatus()}}},[_c('div',{staticClass:"icon_left",class:{
      error: ['ERROR', 'FAILED'].includes(_vm.status),
      success: _vm.status === 'SUCCESS',
    }},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiCogs"}})],1),_c('div',{staticClass:"sections"},[_c('div',{staticClass:"section_left"},[_c('div',{staticClass:"section_info"},[_c('div',{staticClass:"section_title",domProps:{"textContent":_vm._s(_vm.name)}}),_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
            _vm.$t('folder.createdAt', {
              date: _vm.$d(new Date(_vm.dateCreated), 'detailed'),
            })
          )}})])]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"chips",class:_vm.getStatusClass},[_vm._v(_vm._s(_vm.getStatus))])])]),(['ERROR', 'FAILED'].includes(_vm.status))?_c('div',{staticClass:"icon_right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeExpandStatus()}}},[_c('BaseIcon',{attrs:{"icon":_vm.expanded ? '$mdiChevronUp' : '$mdiChevronDown'}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }