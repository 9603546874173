<template>
  <div v-if="isLoading" class="wallet-manager-space-container">
    <BaseSkeletonLoader height="200px" class="skeleton" />
    <BaseSkeletonLoader height="700px" class="skeleton-body" />
  </div>
  <div v-else class="wallet-manager-space-container">
    <div v-if="selectedFile" class="body">
      <wallet-manager-file
        :list-files="listFiles"
        :selected-file="selectedFile"
        :handle_pdf_link="handle_pdf_link"
        @select-file="selectFile"
      />
      <wallet-manager-offer-detail
        :selected-file="selectedFile"
        :client="client"
        :organization="organization"
        :client-list="clients"
        :offer="offer"
        @update="updateOffer"
        @submit="submitComment"
        @onchange="onChange"
      />
    </div>
  </div>
</template>
<script>
import pdf from "pdfvuer";

import WalletManagerFile from "../Components/WalletManagerFile.vue";

import WalletManagerOfferDetail from "../Components/WalletManagerOfferDetail.vue";

import OfferApi from "../../Folder/Services/offer.api";

import {
  convertPDf,
  getDataBlob,
  fileURLToFile,
} from "@/core/Utils/file.utils";

export default {
  name: "WalletManagerSpace",
  components: {
    WalletManagerFile,
    WalletManagerOfferDetail,
  },
  data() {
    return {
      pdfSrc: null,
      listFiles: [],
      element: null,
      selectedId: null,
      comment: null,
      selectedFile: null,
      organization: null,
      clients: [],
      offerType: null,
      isLoading: false,
      offer: null,
    };
  },
  computed: {
    client() {
      const { email } = this.$route.query;
      if (!email) return;
      return this.clients.filter((client) => client.email === email)[0];
    },
  },
  watch: {
    selectedFile: function (newValue) {
      this.selectedFile = newValue;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const { uuid } = this.$route.params;
        const { email } = this.$route.query;
        this.isLoading = true;
        const res = await OfferApi.getOfferDetail(uuid, email);
        if (!res) return;

        const { organization, clients, files, offerType, title, description } =
          res.data;
        this.offer = { title, description };
        this.organization = { ...organization };
        this.clients = [...clients];
        this.offerType = offerType;

        let filesPromises = [];
        files.forEach((file) => {
          return filesPromises.push(getDataBlob(file.offerFile));
        });
        const filesPdf = [];
        Promise.all(filesPromises).then(async (blobFiles) => {
          for (const [index, blobFile] of blobFiles.entries()) {
            const file = await fileURLToFile(
              blobFile,
              files[index].name.split(".").shift()
            );
            const isConvertedNeed = [
              ".xls",
              ".xlsx",
              ".doc",
              ".docx",
              ".ppt",
              ".pptx",
            ].some((type) => file.name.includes(type));

            if (isConvertedNeed) {
              const filePdf = await convertPDf(file);
              const url = URL.createObjectURL(filePdf);
              filesPdf.push({
                url,
                name: `${file.name.split(".").shift()}.pdf`,
              });
            } else {
              const url = URL.createObjectURL(file);
              filesPdf.push({ url, name: file.name });
            }
            this.getListFiles(filesPdf);
          }
        });
        this.isLoading = false;
        if (
          this.client.action === "CONSULT" &&
          this.client.status === "PENDING"
        ) {
          await this.updateOffer("CONSULTED");
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          return this.$router.push("/404");
        }
        if (error?.response?.status === 410) {
          return this.$router.push({
            name: "PageNotFound",
            query: { link_expired: true },
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: error.message,
          type: "ERROR",
        });
        this.isLoading = false;
      }
    },
    async getTotalPage(url) {
      try {
        if (!url) return;
        const data = await pdf.createLoadingTask(url);
        if (!data) return;
        const res = await data.numPages;
        return res;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: error.message,
          type: "ERROR",
        });
      }
    },
    async getListFiles(list) {
      const _temp = await Promise.all(
        list.map(async (item) => {
          const page = await this.getTotalPage(`${item.url}`);

          return {
            name: item.name,
            offerFile: item.url,
            totalPage: page,
          };
        })
      );
      this.selectedFile = _temp[0];
      this.listFiles = _temp;
    },
    handle_pdf_link(params) {
      const page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    findPos(obj) {
      return obj.offsetHeight;
    },
    selectFile(item) {
      this.selectedFile = null;
      setTimeout(() => {
        this.selectedFile = item;
        this.page = 1;
      }, 100);
    },
    async submitComment() {
      const { uuid } = this.$route.params;

      if (this.comment?.length) {
        const payload = { comment: this.comment, email: this.client.email };
        await Promise.all([
          OfferApi.createComment({ payload, uuid }),
          this.updateOffer("DECLINE"),
        ]);
      }

      this.$store.dispatch("snackbar/active", {
        message: this.$t("folder.offerStatus.signatureRefused"),
        type: "SUCCESS",
      });
    },
    onChange(event) {
      this.comment = event;
    },

    async updateOffer(status) {
      const { uuid } = this.$route.params;
      const offerClientId = this.client ? this.client?.id : null;
      const { email } = this.$route.query;
      const client = this.clients.filter((client) => client.email === email)[0];
      let payload = { status };
      let _message = "";
      if (status === "CONSULTED")
        _message = this.$tc("folder.offerStatus.offerConsulted", 0);
      if (status === "ACCEPT")
        _message = this.$tc("folder.offerStatus.offerValidated", 0);
      if (status === "DECLINE") {
        _message = this.$t("folder.offerStatus.signatureRefused");
        payload.reason_decline = this.comment;
      }
      try {
        if (offerClientId) {
          await OfferApi.updateOffer({
            payload,
            uuid,
            offerClientId,
          });

          if (client.action === "CONSULT") client.status = "CONSULTED";
          else
            client.action === "VALIDATION" && status === "DECLINE"
              ? (client.status = "DECLINE")
              : (client.status = "ACCEPT");
        }
        this.clients = this.clients.map((item) => {
          if (item.id === client.id) return { ...item, status: client.status };
          else return item;
        });
        this.$store.dispatch("snackbar/active", {
          message: _message,
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wallet-manager-space-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 50px;
  width: 100vw;
  height: 100vh;
  background: #f4f8ff;
  position: relative;
  @media (max-width: 1980px) {
    padding: 20px;
    overflow: hidden;
  }
  @media (max-width: 480px) {
    padding: 10px;

  }

  .skeleton {
    background: #cfcfdc;
    padding: 24px 32px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }
  .skeleton-body {
    background: #cfcfdc;
    height: 100%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }
  .body {
    display: flex;
    background: #fff;
    height: 100%;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    @media (max-width: 960px) {
      height: auto;
      flex-direction: column !important;
      gap: 67px;
      overflow: auto;
    }
  }
}
</style>
