var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview_container",class:{
    selectable: _vm.selectable,
    editable: _vm.editable,
    disable: _vm.disabled,
    checked: _vm.isCheck,
    expanded: _vm.expanded,
    loading: _vm.loading,
    update__form: _vm.updateNeeded,
    validated__form: _vm.validated,
    updated__form: _vm.updated,
  },on:{"click":function($event){return _vm.$emit('questionClick', [_vm.question])}}},[_c('div',{staticClass:"left_section"},[(_vm.loading)?[_c('div',{staticClass:"question_label"},[_c('v-skeleton-loader',{staticClass:"skeleton",attrs:{"type":"text"}})],1),_vm._m(0)]:(_vm.question)?[_c('div',{staticClass:"check_btn",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.isCheck),callback:function ($$v) {_vm.isCheck=$$v},expression:"isCheck"}})],1),_c('div',{staticClass:"question_label",domProps:{"textContent":_vm._s(_vm.question.name)}}),_c('div',{staticClass:"question_answer"},[(
            _vm.content && /DATAGRID|ROW_DATAGRID/.test(_vm.question.typeQuestion)
          )?[_c('a',{staticClass:"link_content",attrs:{"target":"_blank"},domProps:{"textContent":_vm._s(_vm.$t('utils.goTable'))}})]:(
            _vm.content && !/DATAGRID|ROW_DATAGRID/.test(_vm.question.typeQuestion)
          )?[(_vm.question.typeQuestion === 'MULTIPLE_FILE')?_c('a',{staticClass:"link_content",domProps:{"textContent":_vm._s(("Voir " + (_vm.content.length) + " fichier(s)"))},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = true}}}):(
              _vm.question.typeQuestion !== 'MULTIPLE_FILE' &&
              /FILE|SIGNATURE/.test(_vm.question.typeQuestion)
            )?_c('a',{staticClass:"link_content",attrs:{"href":_vm.content,"target":"_blank"},domProps:{"textContent":_vm._s(_vm.$t('folder.records.seeFile'))},on:{"click":function($event){$event.stopPropagation();}}}):_c('div',{staticClass:"text_content",domProps:{"textContent":_vm._s(_vm.content)}})]:_c('div',{staticClass:"no_content"})],2)]:_vm._e()],2),(_vm.question && _vm.editable)?_c('div',{staticClass:"right_section"},[_c('button',{staticClass:"edit_btn",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editQuestion', [_vm.question])}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiPencilOutline"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.edit'))}})],1)]):_vm._e(),_c('BaseModal',{attrs:{"value":_vm.showDialog,"modal-title":'Liste des fichiers'},on:{"close":function($event){_vm.showDialog = false}},scopedSlots:_vm._u([(_vm.question && _vm.question.answer)?{key:"modal",fn:function(){return _vm._l((_vm.question.answer.content),function(_content){return _c('div',{key:_content.id},[_c('span',{staticClass:"record_question_container",attrs:{"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.openMultiFile(_content)}}},[_c('a',{staticClass:"label link"},[_vm._v(_vm._s(_content.label || _content.name))])])])})},proxy:true}:null,{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Fermer ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question_answer"},[_c('div',{staticClass:"no_content"})])}]

export { render, staticRenderFns }