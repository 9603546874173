<template>
  <div class="destinataire-container">
    <div class="header-item">
      <v-switch
        v-if="
          newOffer.type === OFFER_TYPES.SIGNATURE ||
          newOffer.type === OFFER_TYPES.MIXTE
        "
        v-model="signatures_sequential"
        inset
        flat
        class="ml-auto"
        :label="'Activer la procédure séquentielle'"
      />
    </div>
    <div
      v-for="(signatory, index) in $v.signatories.$each.$iter"
      :key="`${index}+${signatory.$model.email}`"
      class="destinataire-item"
    >
      <div class="destinataire-header">
        <div class="header-title d-flex justify-space-between align-center">
          <span
            v-text="
              `Destinataire ${+index + 1}: ${signatory.$model.email || ''}`
            "
          />
          <div class="action d-flex">
            <div class="button">
              <BaseButtonIcon
                color="primary"
                small
                icon="$mdiArrowUp"
                :disabled="getDisabled(index, 'move_up')"
                @click="moveSignatory(index, 'move_up')"
              />
            </div>
            <div class="button">
              <BaseButtonIcon
                color="primary"
                small
                :disabled="getDisabled(index, 'move_down')"
                icon="$mdiArrowDown"
                @click="moveSignatory(index, 'move_down')"
              />
            </div>
            <div v-if="signatories.length > 1" class="button">
              <BaseButtonIcon
                color="secondary"
                small
                icon="$mdiTrashCanOutline"
                @click.prevent="removeSignatory(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="destinataire-contact">
        <!-- OFFER SIGNATURE SECTION -->
        <div v-if="newOffer.type === OFFER_TYPES.SIGNATURE" class="left-item">
          <div class="item">
            <v-combobox
              v-model="signatories[index]"
              class="contact"
              outlined
              dense
              flat
              :items="formatedSignatories"
              :filter="filterSignatory"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="`${$tc('utils.email', 2)}*`"
              :loading="
                (!activeFolder && !activeFolder.owners) ||
                fetchingsSuggestedSignatories
              "
              item-text="email"
              item-value="uniqId"
              :error-messages="signatoryEmailErrors[index]"
              :has-error="signatoryEmailErrors[index].length > 0"
              @change="setSignatoryDefaultValue(index, 'signer')"
            >
              <template #item="{ item }">
                <div class="signatory_item">
                  <BaseIcon class="icon" :icon="item.icon" />
                  <div class="email" v-text="item.email" />
                  <div
                    v-if="item.lastName || item.firstname"
                    class="separator"
                    v-text="'-'"
                  />
                  <div
                    v-if="item.lastName"
                    class="name"
                    v-text="item.lastName"
                  />
                  <div
                    v-if="item.firstname"
                    class="firstname"
                    v-text="item.firstname"
                  />
                </div>
              </template>
            </v-combobox>
            <BaseTextField
              v-model="signatories[index].phoneNumber"
              dense
              :label="`${$tc('utils.phoneNumber', 3)}*`"
              placeholder="0612345678"
              :error-messages="
                signatoryPhoneErrors && signatoryPhoneErrors[index]
              "
              :has-error="
                signatoryPhoneErrors && signatoryPhoneErrors[index].length > 0
              "
              class="contact"
            />
          </div>

          <div class="item item1">
            <BaseTextField
              v-model="signatories[index].firstName"
              dense
              :label="`${$t('utils.firstName')}*`"
              :error-messages="
                signatoryFirstNameErrors && signatoryFirstNameErrors[index]
              "
              :has-error="
                signatoryFirstNameErrors &&
                signatoryFirstNameErrors[index].length > 0
              "
              class="contact"
            />
            <BaseTextField
              v-model="signatories[index].lastName"
              dense
              :label="`${$t('utils.lastName')}*`"
              :error-messages="
                signatoryLastNameErrors && signatoryLastNameErrors[index]
              "
              :has-error="
                signatoryLastNameErrors &&
                signatoryLastNameErrors[index].length > 0
              "
              class="contact"
            />
          </div>
        </div>
        <!-- OFFER MIXTE SECTION -->
        <div v-else-if="newOffer.type === OFFER_TYPES.MIXTE" class="left-item">
          <div v-if="signatory.$model.action !== 'signer'" class="item">
            <v-combobox
              v-model="signatories[index]"
              class="contact"
              outlined
              dense
              flat
              :items="formatedSignatories"
              :filter="filterSignatory"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="`${$tc('utils.email', 2)}*`"
              :loading="isLoading"
              item-text="email"
              item-value="uniqId"
              :error-messages="signatoryEmailErrors[index]"
              :has-error="signatoryEmailErrors[index].length > 0"
              @focus="activeSignatoryAction = signatory.$model.action"
              @change="setSignatoryDefaultValue(index, activeSignatoryAction)"
            >
              <template #item="{ item }">
                <div class="signatory_item">
                  <BaseIcon class="icon" :icon="item.icon" />
                  <div class="email" v-text="item.email" />
                </div>
              </template>
            </v-combobox>
            <div class="action-type">
              <BaseIcon icon="$mdiCheck" class="mr-2 text-description-normal" />
              <span class="paragraph">Doit {{ signatory.$model.action }}</span>
            </div>
          </div>
          <div v-else>
            <div class="item">
              <v-combobox
                v-model="signatories[index]"
                class="contact"
                outlined
                dense
                flat
                :items="formatedSignatories"
                :filter="filterSignatory"
                :menu-props="{ bottom: true, offsetY: true }"
                :label="`${$tc('utils.email', 2)}*`"
                :loading="isLoading"
                item-text="email"
                item-value="uniqId"
                :error-messages="signatoryEmailErrors[index]"
                :has-error="signatoryEmailErrors[index].length > 0"
                @focus="activeSignatoryAction = signatory.$model.action"
                @change="setSignatoryDefaultValue(index, activeSignatoryAction)"
              >
                <template #item="{ item }">
                  <div class="signatory_item">
                    <BaseIcon class="icon" :icon="item.icon" />
                    <div class="email" v-text="item.email" />
                    <div
                      v-if="item.lastName || item.firstname"
                      class="separator"
                      v-text="'-'"
                    />
                    <div
                      v-if="item.lastName"
                      class="name"
                      v-text="item.lastName"
                    />
                    <div
                      v-if="item.firstname"
                      class="firstname"
                      v-text="item.firstname"
                    />
                  </div>
                </template>
              </v-combobox>
              <BaseTextField
                v-model="signatories[index].phoneNumber"
                dense
                :label="`${$tc('utils.phoneNumber', 3)}*`"
                placeholder="0612345678"
                :error-messages="signatoryPhoneErrors[index]"
                :has-error="signatoryPhoneErrors[index].length > 0"
                class="contact"
              />
              <div class="action-type">
                <BaseIcon
                  icon="$mdiCheck"
                  class="mr-2 text-description-normal"
                />
                <span class="paragraph"
                  >Doit {{ signatory.$model.action }}</span
                >
              </div>
            </div>
            <div class="item item1">
              <BaseTextField
                v-model="signatories[index].firstName"
                dense
                :label="`${$t('utils.firstName')}*`"
                :error-messages="signatoryFirstNameErrors[index]"
                :has-error="signatoryFirstNameErrors[index].length > 0"
                class="contact"
              />
              <BaseTextField
                v-model="signatories[index].lastName"
                dense
                :label="`${$t('utils.lastName')}*`"
                :error-messages="signatoryLastNameErrors[index]"
                :has-error="signatoryLastNameErrors[index].length > 0"
                class="contact"
              />
            </div>
          </div>
        </div>
        <!-- OFFER VALIDATION SECTION -->
        <div v-else class="left-item">
          <div class="item">
            <v-combobox
              v-model="signatories[index]"
              class="contact"
              outlined
              dense
              flat
              :items="formatedSignatories"
              :filter="filterSignatory"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="`${$tc('utils.email', 2)}*`"
              :loading="isLoading"
              item-text="email"
              item-value="uniqId"
              :error-messages="signatoryEmailErrors[index]"
              :has-error="signatoryEmailErrors[index].length > 0"
              @focus="activeSignatoryAction = signatory.$model.action"
              @change="setSignatoryDefaultValue(index, activeSignatoryAction)"
            >
              <template #item="{ item }">
                <div class="signatory_item">
                  <BaseIcon class="icon" :icon="item.icon" />
                  <div class="email" v-text="item.email" />
                </div>
              </template>
            </v-combobox>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="newOffer.type === OFFER_TYPES.SIGNATURE"
      class="add-signatory"
      :class="{ 'is-focused': focused }"
      @click="addSignatory('signer')"
      @mouseenter="focused = true"
      @mouseleave="focused = false"
      v-on="$listeners"
    >
      <base-icon icon="$mdiPlus" small :color="focused ? 'primary' : ''" />
      <span v-text="'Ajouter un destinataire à signer'" />
    </div>
    <div
      v-if="newOffer.type === OFFER_TYPES.VALIDATION"
      class="add-signatory"
      :class="{ 'is-focused': focused }"
      @click="addSignatory('valider')"
      @mouseenter="focused = true"
      @mouseleave="focused = false"
      v-on="$listeners"
    >
      <base-icon icon="$mdiPlus" small :color="focused ? 'primary' : ''" />
      <span v-text="'Ajouter un destinataire à valider'" />
    </div>
    <div
      v-if="newOffer.type === OFFER_TYPES.CONSULT"
      class="add-signatory"
      :class="{ 'is-focused': focused }"
      @click="addSignatory('consulter')"
      @mouseenter="focused = true"
      @mouseleave="focused = false"
      v-on="$listeners"
    >
      <base-icon icon="$mdiPlus" small :color="focused ? 'primary' : ''" />
      <span v-text="'Ajouter un destinataire à consulter'" />
    </div>
    <div
      v-if="newOffer.type === OFFER_TYPES.MIXTE"
      class="mixte d-flex space-between"
    >
      <div
        class="add-signatory"
        :class="{ 'is-focused': consultFocused }"
        @click="addSignatory('consulter')"
        @mouseenter="consultFocused = true"
        @mouseleave="consultFocused = false"
        v-on="$listeners"
      >
        <base-icon
          icon="$mdiPlus"
          small
          :color="consultFocused ? 'primary' : ''"
        />
        <span v-text="'Ajouter un destinataire à consulter'" />
      </div>
      <div
        class="add-signatory"
        :class="{ 'is-focused': validFocused }"
        @click="addSignatory('valider')"
        @mouseenter="validFocused = true"
        @mouseleave="validFocused = false"
        v-on="$listeners"
      >
        <base-icon
          icon="$mdiPlus"
          small
          :color="validFocused ? 'primary' : ''"
        />
        <span v-text="'Ajouter un destinataire à valider'" />
      </div>
      <div
        class="add-signatory"
        :class="{ 'is-focused': signFocused }"
        @click="addSignatory('signer')"
        @mouseenter="signFocused = true"
        @mouseleave="signFocused = false"
        v-on="$listeners"
      >
        <base-icon
          icon="$mdiPlus"
          small
          :color="signFocused ? 'primary' : ''"
        />
        <span v-text="'Ajouter un destinataire à signer'" />
      </div>
    </div>
    <div class="action-container">
      <BaseButton class="ml-auto" type="primary" outlined @click="goBackStep">
        <Base-icon small icon="$mdiArrowLeftThick" />
        <span class="ml-2" v-text="$t('utils.back')" />
      </BaseButton>

      <BaseButton type="primary" @click="moveNextStep">
        <span class="mr-2" v-text="$t('utils.next')" />
        <Base-icon small icon="$mdiArrowRightThick" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { OFFER_TYPES } from "@/core/Utils/types.utils";
import {
  required,
  minLength,
  maxLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { Signatory, SIGNATORY_TYPE } from "@/core/Models/Signatory";

export default {
  name: "FolderOfferStep2",
  mixins: [validationMixin],
  data() {
    return {
      focused: false,
      consultFocused: false,
      validFocused: false,
      signFocused: false,
      signatories: [],
      client: [],
      suggestedSignatories: [],
      signatures_sequential: false,
      fetchingsSuggestedSignatories: false,
      OFFER_TYPES: OFFER_TYPES,
      upDisabled: false,
      activeSignatoryAction: "",
    };
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      newOffer: (state) => state.offer.newOffer,
    }),
    formatedSignatories() {
      return [
        ...this.activeFolder.emails.map(
          (s) =>
            new Signatory({
              ...s,
              firstName: s.firstname,
              lastName: s.lastname,
              phoneNumber: s.phone,
              type: SIGNATORY_TYPE.FOLDER_SIGNATORY,
            })
        ),
        ...this.suggestedSignatories,
      ];
    },
    isLoading() {
      return (
        (!this.activeFolder && !this.activeFolder.owners) ||
        this.fetchingsSuggestedSignatories
      );
    },
    signatoryFirstNameErrors() {
      const errors = [];
      for (
        let i = 0;
        i < Object.keys(this.$v.signatories.$each.$iter).length;
        i++
      ) {
        errors.push([]);
        if (!this.$v.signatories.$each.$iter[i.toString()].firstName) return;
        if (!this.$v.signatories.$each.$iter[i.toString()].firstName.$dirty) {
          continue;
        }
        if (!this.$v.signatories.$each.$iter[i.toString()].firstName.required) {
          errors[i].push(this.$t("folder.yousign.errors.lastNameRequired"));
          continue;
        }
      }
      return errors;
    },
    signatoryLastNameErrors() {
      const errors = [];
      for (
        let i = 0;
        i < Object.keys(this.$v.signatories.$each.$iter).length;
        i++
      ) {
        errors.push([]);
        if (!this.$v.signatories.$each.$iter[i.toString()].lastName) return;
        if (!this.$v.signatories.$each.$iter[i.toString()].lastName.$dirty) {
          continue;
        }
        if (!this.$v.signatories.$each.$iter[i.toString()].lastName.required) {
          errors[i].push(this.$t("folder.yousign.errors.nameRequired"));
          continue;
        }
      }
      return errors;
    },
    signatoryEmailErrors() {
      const errors = [];
      for (
        let i = 0;
        i < Object.keys(this.$v.signatories.$each.$iter).length;
        i++
      ) {
        errors.push([]);
        if (!this.$v.signatories.$each.$iter[i.toString()].email) continue;
        if (!this.$v.signatories.$each.$iter[i.toString()].email.$dirty) {
          continue;
        }
        if (!this.$v.signatories.$each.$iter[i.toString()].email.required) {
          errors[i].push(this.$t("folder.yousign.errors.emailRequired"));
          continue;
        }
        if (!this.$v.signatories.$each.$iter[i.toString()].email.email) {
          errors[i].push(this.$t("folder.yousign.errors.emailError"));
          continue;
        }
        if (
          !this.$v.signatories.$each.$iter[i.toString()].email.isEmailUnique
        ) {
          errors[i].push(this.$t("email dupliquer"));
          continue;
        }
      }
      return errors;
    },
    signatoryPhoneErrors() {
      const errors = [];
      for (
        let i = 0;
        i < Object.keys(this.$v.signatories.$each.$iter).length;
        i++
      ) {
        errors.push([]);
        if (!this.$v.signatories.$each.$iter[i.toString()].phoneNumber) return;
        if (!this.$v.signatories.$each.$iter[i.toString()].phoneNumber.$dirty) {
          continue;
        }
        if (
          !this.$v.signatories.$each.$iter[i.toString()].phoneNumber.required
        ) {
          errors[i].push(this.$t("folder.yousign.errors.phoneNumberRequired"));
          continue;
        }
        if (
          !this.$v.signatories.$each.$iter[i.toString()].phoneNumber.phoneNumber
        ) {
          errors[i].push(this.$t("folder.yousign.errors.phoneNumberError"));
          continue;
        }
      }
      return errors;
    },
  },
  watch: {
    "newOffer.type": {
      handler() {
        this.client.push(this.activeFolder?.client?.id);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initialize();
  },
  validations() {
    let validations = {};
    if (this.newOffer.type === OFFER_TYPES.MIXTE && this.signatories.length) {
      validations.signatories = {
        $each: {
          email: {
            required,
            maxLength: maxLength(255),
            email,
            isEmailUnique: (value) => {
              return (
                this.signatories
                  .filter((s) => s.email == value)
                  .map((sm) => sm.email).length <= 1
              );
            },
          },
          firstName: {
            required: requiredIf((self) => self?.action === "signer"),
            maxLength: maxLength(255),
          },
          lastName: {
            required: requiredIf((self) => self?.action === "signer"),
            maxLength: maxLength(255),
          },
          phoneNumber: {
            required: requiredIf((self) => self?.action === "signer"),
            phoneNumber: (value, self) => {
              if (self?.action === "signer") return /^0+[0-9]{9}$/.test(value);
              else return true;
            },
          },
        },
      };
    } else if (this.newOffer.type === OFFER_TYPES.SIGNATURE) {
      validations.signatories = {
        required,
        minLength: minLength(1),
        $each: {
          firstName: {
            required,
            maxLength: maxLength(255),
          },
          lastName: {
            required,
            maxLength: maxLength(255),
          },
          email: {
            required,
            maxLength: maxLength(255),
            email,
            isEmailUnique: (value) => {
              return (
                this.signatories
                  .filter((s) => s.email == value)
                  .map((sm) => sm.email).length <= 1
              );
            },
          },
          phoneNumber: {
            required,
            phoneNumber: (value) => {
              return /^0+[0-9]{9}$/.test(value);
            },
          },
        },
      };
    } else {
      validations.signatories = {
        required,
        minLength: minLength(1),
        $each: {
          email: {
            required,
            maxLength: maxLength(255),
            email,
            isEmailUnique: (value) => {
              return (
                this.signatories
                  .filter((s) => s.email == value)
                  .map((sm) => sm.email).length <= 1
              );
            },
          },
        },
      };
    }
    return validations;
  },
  methods: {
    async initialize() {
      let action = "";
      switch (this.newOffer.type) {
        case OFFER_TYPES.SIGNATURE:
          this.signatures_sequential = this.newOffer.signatures_sequential;
          action = "signer";
          break;
        case OFFER_TYPES.VALIDATION:
          action = "valider";
          break;
        default:
          action = "consulter";
      }
      if (this.newOffer.type !== OFFER_TYPES.MIXTE) this.addSignatory(action);
      await this.fetchSSignatories();
    },
    async fetchSSignatories() {
      this.fetchingsSuggestedSignatories = true;
      try {
        const tmpSignatories = await Promise.all([
          await DashboardAPI.getUserSignatories(),
          await DashboardAPI.getOrganizationSignatories({
            organizationId: this.$route.params.organizationId,
          }),
        ]);
        this.suggestedSignatories = [
          ...tmpSignatories[0].data.results.map(
            (s) =>
              new Signatory({ ...s, type: SIGNATORY_TYPE.PERSONAL_SIGNATORY })
          ),
          ...tmpSignatories[1].data.results.map(
            (s) =>
              new Signatory({
                ...s,
                type: SIGNATORY_TYPE.ORGANIZATION_SIGNATORY,
              })
          ),
        ];
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingsSuggestedSignatories = false;
    },
    moveNextStep() {
      this.$v.signatories.$touch();
      if (this.$v.signatories.$invalid) return;
      this.$store.commit("offer/setNewOffer", {
        ...this.newOffer,
        signatures_sequential: this.signatures_sequential,
        signatories: [...this.signatories],
        client: [...this.client],
      });
      this.$store.commit("offer/setOfferStep", 3);
    },
    goBackStep() {
      this.$store.commit("offer/setOfferStep", 1);
    },
    addSignatory(action) {
      this.signatories.push({
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        action: action,
      });
    },
    setSignatoryDefaultValue(index, action) {
      if (!this.signatories[index]) return;
      if ("string" != typeof this.signatories[index]) {
        this.signatories[index] = new Signatory({
          ...this.signatories[index],
          action,
        });
        return;
      }
      this.signatories[index] = new Signatory({
        email: this.signatories[index],
        action,
      });
    },
    moveSignatory(idx, move) {
      const index = parseInt(idx);
      const _temp = [...this.signatories];
      let el = _temp[index];
      if (index > 0 && move === "move_up") {
        _temp[index] = _temp[index - 1];
        _temp[index - 1] = el;
        this.signatories = [..._temp];
      }
      if (index !== -1 && index < _temp.length - 1 && move === "move_down") {
        _temp[index] = _temp[index + 1];
        _temp[index + 1] = el;
        this.signatories = [..._temp];
      }
    },
    removeSignatory(index) {
      this.signatories.splice(index, 1);
    },
    getDisabled(index, move) {
      if (!this.signatories.length) return;
      if (parseInt(index) === 0 && move === "move_up") {
        return true;
      }
      if (
        parseInt(index) === this.signatories.length - 1 &&
        move === "move_down"
      )
        return parseInt(index) + 1 === this.signatories.length;
    },
    filterSignatory(item, queryText) {
      if (!queryText) return true;
      return (
        item.email &&
        item.email.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.destinataire-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-item {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .destinataire-item {
    border-radius: 5px;
    border: 1px dashed #cfcfdc;
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    .destinataire-header {
      height: 57px;
      padding: 10px;
      width: 100%;
      height: 100%;
      .header-title {
        padding: 10px;
        border-radius: 5px;
        background: var(--principale-gris-fond-de-tableau, #fcfcfc);
      }
      .action {
        gap: 10px;
        .button {
          padding: 8px 16px;
          border-radius: 5px;
          background: #f4f7ff;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .destinataire-contact {
      padding: 10px;
      display: flex;
      gap: 24px;
      .left-item {
        flex: 2;
        display: flex;
        flex-direction: column;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          .action-type {
            display: flex;
            border-radius: 5px;
            background: #fcfcfc;
            justify-content: center;
            align-items: center;
            padding: 8.75px 16px;
            margin-top: -23px;
            .paragraph {
              color: #707080;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .item1 {
          margin-top: -6px;
        }
        .mixt {
          width: 527px;
        }
        .action {
          display: flex;
          align-items: center;
          border-radius: 5px;
          background: #fcfcfc;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          .paragraph {
            color: #707080;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .right-item {
        flex: 1;
      }
    }
  }
}
::v-deep .v-list-item--link {
  margin: 8px;
  &:hover {
    background: rgba(155, 189, 255, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }
}
::v-deep .v-list-item--active {
  background: rgba(155, 189, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.signatory_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  color: #323338;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  .separator {
    margin: 0;
  }
  .icon {
    ::v-deep {
      .v-icon__svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .name {
    text-transform: uppercase;
  }
  .firstname {
    text-transform: capitalize;
  }
}

.add-signatory {
  height: 42px;
  width: 100%;
  margin-bottom: 48px;
  border-radius: 5px;
  border: 1px dashed var(--principale-gris-texte, #707080);
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--principale-gris-texte, #707080);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 16px;
  cursor: pointer;
}
.is-focused {
  border-color: #2536cc;
  color: #2536cc;
}

.mixte {
  gap: 10px;
  margin-bottom: 48px;
  .add-signatory {
    width: 400px;
    margin: auto;
  }
}
::v-deep .label {
  color: var(--gris-texte, #707080);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact {
  flex: 1;
}
::v-deep fieldset {
  background: #f4f8ff;
  border: 0px;
}
.action-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 12px;
}
.mail-chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}
</style>
