<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('utils.deleteLabel.title')"
    is-unique
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-label">
        {{ $t("utils.deleteLabel.confirmation") }}
      </div>
    </template>
    <template #actions>
      <BaseButton
        type="primary"
        class="mx-2 ml-auto"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import RecordAPI from "../../../Wallet/Services/record.api";
export default {
  name: "FolderFileConfirmDeleteDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    fileToDelete: {
      type: [Object, Number],
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({ activeFolder: (state) => state.folder.activeFolder }),
  },
  methods: {
    reset() {
      this.$emit("delete");
      this.$emit("close");
      this.isLoading = false;
    },
    async submit() {
      this.isLoading = true;
      if (!this.fileToDelete) return this.reset();
      try {
        let payload = {};
        if (this.fileToDelete.deletionEndpoint.includes("answer-file")) {
          payload = {
            personal_data_owner: this.activeFolder.client.id,
            organization: this.$route.params.organizationId,
            folder: this.activeFolder.id,
          };
        }
        await RecordAPI.deleteFileFromRecord({
          endpoint: this.fileToDelete.deletionEndpoint.slice(8),
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.deleteLabel.successfully"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: error.message || "error",
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-label {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
