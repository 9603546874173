var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{attrs:{"value":_vm.dialogState,"modal-title":"Envoyer les champs"},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s(_vm.$t('cosmoform.dialog.concernedFields'))}}),_c('div',{staticClass:"input-fields"},[_c('div',{staticClass:"cosmo-fields-container"},_vm._l((_vm.groupedQuestionsByCategory),function(group,i){return _c('div',{key:("g-" + (group.id + 1) + i),staticClass:"my-2"},[(group.id)?_c('div',{staticClass:"question_category"},[_vm._v(" "+_vm._s(group.name)+" ")]):_vm._e(),_c('div',{staticClass:"question_list"},_vm._l((group.questions),function(question){return _c('div',{key:("gq-" + (question.id + 1) + i),staticClass:"question_item"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(question.name)+" ")]),_c('div',{staticClass:"ml-2"},[_c('BaseIcon',{attrs:{"color":question.readOnly ? 'primary' : '#CFCFDC',"small":"","icon":"$mdiPencilOffOutline"}}),_c('BaseButtonIcon',{attrs:{"icon":"$mdiClose","color":"primary"},on:{"click":function($event){return _vm.unSelectQuestion(question.id)}}})],1)])}),0)])}),0)])]),_c('div',[_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s('Formulaire')}}),_c('div',{staticClass:"input-fields"},[_c('div',{staticClass:"cosmoform_template_form"},[_c('span',{staticClass:"label",domProps:{"textContent":_vm._s('Template')}}),_c('BaseComboBox',{attrs:{"value":_vm.form.title,"items":_vm.cosmoformTemplates,"loading":_vm.isFetchingCosmoformTemplates ||
                  _vm.isFetchingCosmoformTemplateDetail,"solo":"","outlined":"","item-text":"title","item-value":"id","need-selection":"","disabled":_vm.isDisabled},on:{"change":_vm.setFormValueByTemplate},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item))]),(item === _vm.defaultCosmoformTitle)?_c('span',{staticClass:"select-default ml-3",domProps:{"textContent":_vm._s('(Par défaut)')}}):_vm._e()]}}])}),_c('BaseMarkdownEditor',{attrs:{"label":"Description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)])]),_c('div',[_c('span',{staticClass:"sub-title",domProps:{"textContent":_vm._s('Message et destinataires')}}),_c('div',{staticClass:"input-fields last-input"},[_c('BaseComboBox',{attrs:{"items":_vm.emails,"label":((_vm.$t('cosmoform.dialog.email')) + " *"),"error-messages":_vm.emailErrors,"has-error":_vm.emailErrors !== '',"chips":"","solo":"","outlined":"","need-selection":"","disabled":_vm.emailFormDisabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var attrs = ref.attrs;
                  var item = ref.item;
                  var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mail-chips",attrs:{"input-value":selected,"close":!_vm.emailFormDisabled},on:{"click:close":function($event){_vm.form.email = ''}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"input_group mb-4"},[_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$t('cosmoform.dialog.emailContent'))}}),_c('BaseMarkdownEditor',{attrs:{"with-link":false},model:{value:(_vm.form.emailContent),callback:function ($$v) {_vm.$set(_vm.form, "emailContent", $$v)},expression:"form.emailContent"}})],1),_c('v-switch',{staticClass:"checkbox",attrs:{"inset":"","hide-details":"","false-value":true,"true-value":false,"label":_vm.$t('cosmoform.dialog.accountRequired')},model:{value:(_vm.form.public),callback:function ($$v) {_vm.$set(_vm.form, "public", $$v)},expression:"form.public"}})],1)])])]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"disabled":!_vm.isFormValid,"loading":_vm.isSubmitting,"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])]},proxy:true}])}),_c('folder-confirm-assign-dialog',{attrs:{"is-open":_vm.openConfirmModal,"confirm-email":_vm.form.email},on:{"on-validate":function($event){return _vm.onSave(true)},"close":function () {
        _vm.openConfirmModal = false;
        _vm.isSubmitting = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }