<template>
  <BaseCard class="pa-6" is-rounded>
    <div class="d-flex flex-column">
      <BaseParagraph v-text="cartTitle" />
      <!-- info folder -->
      <div v-if="folderInfo">
        <BaseCaption
          v-if="folder"
          text="secondary"
          uppercase
          v-text="
            $t('folder.createdAt', {
              date: $d(new Date(folder.dateCreated), 'detailed'),
            })
          "
        />
        <FullDataTable
          v-for="(context, index) in data"
          :key="`${context}-${index}`"
          :cart-title="context.name"
          :item-data="context.questions"
        />
      </div>
      <!-- end folderInfo -->

      <!-- client collect -->
      <div v-if="clientCollect">
        <div
          v-for="(context, index) in data"
          :key="`${context}-${index}`"
          class="d-flex flex-column"
        >
          <BaseFrontOfficeTitle
            color="primary"
            class="my-2"
            v-text="context.course.name"
          />
          <BaseParagraph v-text="getFullClientData(context.client)" />
          <BaseCaption
            text="secondary"
            uppercase
            v-text="
              $tc('folder.clientFullData.consentDate', 1, {
                dateCreated: $d(new Date(context.dateCreated), 'detailed'),
                consentDate: $d(new Date(context.consentDate), 'detailed'),
              })
            "
          />
          <FullDataTable
            v-for="(step, i) in context.steps"
            :key="i"
            :cart-title="step.name"
            :item-data="step.questions"
          />
        </div>
      </div>
      <!-- end client collect -->
      <!-- folder offers -->
      <div v-if="offerInfo">
        <div
          v-for="(offer, index) in data"
          :key="`${offer}-${index}`"
          class="d-flex flex-column"
        >
          <BaseFrontOfficeTitle
            color="primary"
            class="my-2"
            v-text="offer.title"
          />
          <BaseCaption
            text="secondary"
            uppercase
            v-text="
              $tc('folder.clientFullData.consentDate', 2, {
                dateCreated: $d(new Date(offer.dateCreated), 'detailed'),
              })
            "
          />
          <FullDataTable
            v-for="(_client, i) in getClient(offer)"
            :key="i"
            cart-title="Client"
            :item-data="getClientData(_client, offer)"
            is-offer-detail
            is-offer-client
          />
          <FullDataTable
            :cart-title="$tc('folder.records.file', 3)"
            :item-data="getOfferFile(offer)"
            is-offer-detail
            is-offer-file-client
          />
        </div>
      </div>
      <!-- end folder Offers -->
    </div>
  </BaseCard>
</template>

<script>
import FullDataTable from "./FullDataTable.vue";
export default {
  name: "FullDataCardContent",
  components: { FullDataTable },
  props: {
    needTitle: {
      type: Boolean,
    },
    folderInfo: {
      type: Boolean,
    },
    clientCollect: {
      type: Boolean,
    },
    offerInfo: {
      type: Boolean,
    },
    cartTitle: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    folder: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getOfferFile(offer) {
      if (offer.offerType === "SIGNATURE") {
        return [...offer.files, ...this.getSignedFileProof(offer)];
      }
      return offer.files;
    },
    getSignedFileProof(offer) {
      const fileProofs = offer.proofs.map((p) => {
        return {
          name: `${this.$t("folder.proofOfSignature")} (${p.firstName} ${
            p.lastName
          })`,
          signedFile: p.proof,
        };
      });
      return fileProofs;
    },
    getClient(offer) {
      if (offer.offerType === "SIGNATURE") return offer.yousignMembers;
      return offer.clients;
    },
    getFullClientData(client) {
      if (!client) return "Anonyme";
      if (client.firstname === "" && client.lastname === "")
        return `Anonyme, ${client.email}`;
      return `${client.firstname} ${client.lastname}, ${client.email}`;
    },
    getStatus(status, offerType) {
      switch (offerType) {
        case "VALIDATION": {
          if (status === "DECLINE") {
            return this.$tc("folder.offerStatus.offerDeclined", 3);
          }
          if (status === "ACCEPT") {
            return this.$tc("folder.offerStatus.offerValidated", 3);
          }
          return this.$tc("folder.offerStatus.waitingForValidation");
        }
        case "CONSULT": {
          if (status === "PENDING") {
            return this.$t("folder.offerStatus.notConsulted");
          }
          return this.$tc("folder.offerStatus.offerConsulted", 3);
        }
      }
    },
    getStatusOfSignature(client, offer) {
      if (client.proof) return this.$t("folder.offerStatus.OfferSigned");
      const cosmoClient = offer.clients.find(
        (cli) => cli.member?.id === client.id
      );
      if (!cosmoClient)
        return this.$t("folder.offerStatus.waitingForSignature");
      if (cosmoClient.status === "DECLINE")
        return this.$t("folder.offerStatus.signatureRefused");
      else if (cosmoClient.status === "CANCELED")
        return this.$t("folder.offerStatus.offerCanceled");
      return this.$t("folder.offerStatus.waitingForSignature");
    },
    getClientData(item, offer) {
      if (offer.offerType !== "SIGNATURE") {
        return [
          {
            text: this.$t("utils.firstName"),
            value: item.lastname !== "" ? item.lastname : "Anonyme",
          },
          {
            text: this.$t("utils.lastName"),
            value: item.firstname !== "" ? item.firstname : "Anonyme",
          },
          { text: this.$t("utils.email"), value: item.email },
          {
            text: this.$tc("utils.status", 1),
            value: this.getStatus(item.status, offer.offerType),
          },
        ];
      }
      return [
        {
          text: this.$t("utils.firstName"),
          value: item.firstname !== "" ? item.lastname : "Anonyme",
        },
        {
          text: this.$t("utils.lastName"),
          value: item.firstname !== "" ? item.lastname : "Anonyme",
        },
        { text: this.$t("utils.email"), value: item.email },
        { text: this.$tc("utils.phoneNumber", 1), value: item.phoneNumber },
        {
          text: this.$tc("utils.status", 1),
          value: this.getStatusOfSignature(item, offer),
        },
      ];
    },
  },
};
</script>
