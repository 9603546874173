var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"record_item_container",class:{ container_secondary: _vm.record.status == 'INVITED' }},[(_vm.record)?_c('FolderRecordItemHeader',{attrs:{"record":_vm.record,"expanded":_vm.expanded,"is-spam":_vm.isSpam,"is-updated":_vm.isUpdated},on:{"expandChange":function($event){return _vm.changeExpandStatus()}}}):_vm._e(),(_vm.expanded)?[_c('div',{class:_vm.classObject},[_c('div',{staticClass:"record_detail_container"},[(_vm.record.status !== 'AGREE')?_c('div',{staticClass:"relaunch_container"},[_c('div',{staticClass:"relaunch_card"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"relaunch_btn",class:{ relaunched_btn: _vm.relaunchNumber > 0 },attrs:{"disabled":_vm.isArchivedFolder},on:{"click":function($event){return _vm.openRelaunchModal()}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiEmailOutline"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('course.relaunch'))}})],1)]),_c('div',{staticClass:"relaunch_detail",domProps:{"textContent":_vm._s(
                _vm.$t('utils.relaunchNumber', {
                  value: _vm.relaunchNumber,
                })
              )}})])]):_vm._e(),_c('div',{staticClass:"separator"}),_c('v-tabs',{staticClass:"tab_header",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{staticClass:"tab_header_item"},[_vm._v(" "+_vm._s(_vm.$t("utils.all"))+" ")]),_vm._l((_vm.record.steps),function(step,i){return _c('v-tab',{key:("sh-" + i),staticClass:"tab_header_item"},[_vm._v(" "+_vm._s(step.name)+" ")])})],2),_c('div',{staticClass:"record_actions"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"question_filter",attrs:{"depressed":"","plain":""}},'v-btn',attrs,false),on),[_c('v-checkbox',{staticClass:"custom_checkbox",attrs:{"value":_vm.isCheck,"true-value":true,"false-value":false,"readonly":"","color":"primary","hide-details":""},on:{"click":function($event){_vm.checkedQuestions = []}}}),_c('BaseIcon',{attrs:{"icon":"$mdiChevronDown"}})],1)]}}],null,false,754784917)},[_c('div',{staticClass:"menu_list"},[_c('v-btn',{staticClass:"menu_list_item",class:{
                  active: _vm.activeCheckQuestionType === _vm.CHECK_QUESTION_TYPE.ALL,
                },attrs:{"x-large":"","block":"","text":""},on:{"click":function($event){return _vm.checkQuestion(_vm.CHECK_QUESTION_TYPE.ALL)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.allFields"))+" ")]),_c('v-btn',{staticClass:"menu_list_item",class:{
                  active:
                    _vm.activeCheckQuestionType ===
                    _vm.CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE,
                },attrs:{"x-large":"","block":"","text":""},on:{"click":function($event){return _vm.checkQuestion(_vm.CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.allFiles"))+" ")]),_c('v-btn',{staticClass:"menu_list_item",class:{
                  active:
                    _vm.activeCheckQuestionType === _vm.CHECK_QUESTION_TYPE.ANSWERED,
                },attrs:{"x-large":"","block":"","text":""},on:{"click":function($event){return _vm.checkQuestion(_vm.CHECK_QUESTION_TYPE.ANSWERED)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.filledFields"))+" ")]),_c('v-btn',{staticClass:"menu_list_item",class:{
                  active:
                    _vm.activeCheckQuestionType ===
                    _vm.CHECK_QUESTION_TYPE.NOT_ANSWERED,
                },attrs:{"x-large":"","block":"","text":""},on:{"click":function($event){return _vm.checkQuestion(_vm.CHECK_QUESTION_TYPE.NOT_ANSWERED)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.emptyFields"))+" ")])],1)]),(_vm.checkedQuestions.length)?[_c('BaseButton',{attrs:{"loading":_vm.activeState === _vm.COMPONENT_STATES.EXPORTING_RECORD,"type":"primary","icon":"$mdiFolderZip"},on:{"click":function($event){return _vm.downloadCheckedQuestionInZip()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.downloadInZipFormat"))+" ")]),(!_vm.recordAnonymous && _vm.displayInValidatedButton)?_c('BaseButton',{attrs:{"color":"primary","icon":"$mdiPlaylistEdit","disabled":_vm.isArchivedFolder},on:{"click":function($event){return _vm.openSendInvalidateAnswersDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.requestEditing"))+" ")]):_vm._e(),(!_vm.recordAnonymous && _vm.displayValidatedButton)?_c('BaseButton',{attrs:{"loading":_vm.activeState === _vm.COMPONENT_STATES.VALIDATE_QUESTION,"color":"primary","icon":"$mdiCheck","disabled":_vm.isArchivedFolder},on:{"click":function($event){return _vm.validateCheckedQuestion()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")]):_vm._e()]:_vm._e()],2),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('div',{staticClass:"tab_item"},_vm._l((_vm.record.steps),function(step,i){return _c('div',{key:("as-" + i),staticClass:"step_questions"},[_c('div',{staticClass:"step_label",domProps:{"textContent":_vm._s(step.name)}}),_c('div',{staticClass:"step_questions_grid"},_vm._l((step.questions.filter(
                      function (q) { return q.typeQuestion !== 'MESSAGE'; }
                    )),function(question,qi){return _c('FolderPreviewQuestion',{key:("q-" + qi),attrs:{"editable":false,"disabled":!question.answer,"question":question},on:{"questionClick":_vm.openQuestionValidation},model:{value:(_vm.checkedQuestions),callback:function ($$v) {_vm.checkedQuestions=$$v},expression:"checkedQuestions"}})}),1)])}),0)]),_vm._l((_vm.record.steps),function(step,i){return _c('v-tab-item',{key:("sti-" + i)},[_c('div',{staticClass:"tab_item"},[_c('div',{staticClass:"step_questions"},[_c('div',{staticClass:"step_questions_grid"},_vm._l((step.questions.filter(
                      function (q) { return q.typeQuestion !== 'MESSAGE'; }
                    )),function(question,qi){return _c('FolderPreviewQuestion',{key:("q-" + qi),attrs:{"editable":!!question.answer,"disabled":!question.answer,"question":question},on:{"questionClick":_vm.openQuestionValidation},model:{value:(_vm.checkedQuestions),callback:function ($$v) {_vm.checkedQuestions=$$v},expression:"checkedQuestions"}})}),1)])])])})],2)],1)])]:_vm._e(),_c('FolderSendInvalidateAnswersDialog',{attrs:{"dialog-state":_vm.activeState === _vm.COMPONENT_STATES.OPEN_INVALIDATE_QUESTION,"record":_vm.record,"questions":_vm.checkedQuestions},on:{"close":function($event){return _vm.reset()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }