import CosmoUser from "./CosmoUser";
import Organization from "./Organization";

export default class User extends CosmoUser {
  constructor({
    id = Number.NaN,
    email = "",
    firstname = "",
    lastname = "",
    avatar = "",
    isStaff = false,
    activeOrganization = null,
    phoneNumber = "",
  } = {}) {
    super({ id, email, firstname, lastname });
    this.avatar = avatar;
    this.isStaff = isStaff;
    this.phoneNumber = phoneNumber;
    this.activeOrganization = activeOrganization
      ? new Organization(activeOrganization)
      : null;
  }
}
