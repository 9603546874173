import CourseAPI from "./course.api";
import Course from "@/core/Models/Course";
import Question from "@/core/Models/Question";
import Option from "@/core/Models/Option";

export function getQuestion(payload) {
  return new Question(payload);
}

export function getOption(payload) {
  return new Option(payload);
}

export function createOrganizationCourse(organizationId, courseId, course) {
  return new Promise((resolve, reject) => {
    CourseAPI.postOrganizationCourse(organizationId, courseId, course)
      .then((r) => {
        resolve(r.data);
      })
      .catch((r) => {
        reject(r);
      });
  });
}

export function toggleOrganizationCourseArchived(
  organizationId,
  courseId,
  archived
) {
  return CourseAPI.postToggleArchiveOrganizationCourse(
    organizationId,
    courseId,
    archived
  );
}

export function fetchCourseStep(organizationId, courseId, stepId) {
  return CourseAPI.findCourseStep(organizationId, courseId, stepId);
}
export function createCourseStep(organizationId, courseId, { name, order }) {
  return new Promise((resolve, reject) => {
    CourseAPI.postCourseStep(organizationId, courseId, { name, order })
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}
export function createCourseStepQuestion(
  organizationId,
  courseId,
  stepId,
  question
) {
  let payload = new FormData();
  payload.append("name", question.name);
  payload.append("label", question.label);
  payload.append("order", question.order);
  if (question.has_free_option) {
    payload.append("has_free_option", question.has_free_option);
  }
  question.datatype ? payload.append("datatype", question.datatype) : null;
  question.typeQuestion
    ? payload.append("type_question", question.typeQuestion)
    : null;
  question.required ? payload.append("required", question.required) : null;
  question.helpText ? payload.append("help_text", question.helpText) : null;
  question.image ? payload.append("image", question.image) : null;
  question.helpFile ? payload.append("help_file", question.helpFile) : null;

  return new Promise((resolve, reject) => {
    CourseAPI.postCourseStepQuestion(
      organizationId,
      courseId,
      stepId,
      payload,
      !!question.datatype
    )
      .then((r) => {
        if (question.choices && question.choices.length) {
          let promises = [];
          question.choices.forEach((choice) => {
            promises.push(
              CourseAPI.postCourseStepQuestionOption(
                organizationId,
                courseId,
                stepId,
                r.data.id,
                { name: choice.name, order: choice.order }
              )
            );
          });
          Promise.all(promises)
            .then((responses) => {
              responses.forEach((response) => {
                r.data.choices.push(getOption(response.data));
              });
              resolve(r.data);
            })
            .catch((err) => reject(err));
        } else resolve(r.data);
      })
      .catch((e) => reject(e));
  });
}
export function deleteCourseStep(organizationId, courseId, stepId) {
  return new Promise((resolve, reject) => {
    CourseAPI.deleteCourseStep(organizationId, courseId, stepId)
      .then(() => resolve())
      .catch((e) => reject(e));
  });
}
export function deleteCourseStepQuestion(
  organizationId,
  courseId,
  stepId,
  questionId
) {
  return CourseAPI.deleteCourseStepQuestion(
    organizationId,
    courseId,
    stepId,
    questionId
  );
}
export function deleteOrganizationCourse(organizationId, courseId) {
  return new Promise((resolve, reject) => {
    CourseAPI.deleteOrganizationCourse(organizationId, courseId)
      .then((r) => {
        resolve(r);
      })
      .catch((r) => {
        reject(r);
      });
  });
}
export function duplicateOrganizationCourse(
  organizationId,
  courseId,
  courseName
) {
  return new Promise((resolve, reject) => {
    CourseAPI.postOrganizationDuplicateCourse(organizationId, courseId, {
      name: courseName,
    })
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}
export function editCourseStep(organizationId, courseId, { id, name, order }) {
  return new Promise((resolve, reject) => {
    CourseAPI.patchCourseStep(organizationId, courseId, id, { name, order })
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}
export async function patchCourseStepQuestion(
  organizationId,
  courseId,
  stepId,
  questionId,
  question
) {
  return CourseAPI.patchCourseStepQuestion(
    organizationId,
    courseId,
    stepId,
    questionId,
    question
  );
}

export async function editCourseStepQuestion(
  organizationId,
  courseId,
  stepId,
  questionId,
  question,
  deletedOptionsIds,
  isOptionChanged
) {
  let payload = new FormData();
  if (question.name !== undefined) {
    payload.append("name", question.name);
  }
  if (question.label !== undefined) {
    payload.append("label", question.label);
  }
  if (question.order !== undefined) {
    payload.append("order", question.order);
  }
  if (question.helpText !== undefined) {
    payload.append("help_text", question.helpText);
  }
  if (question.typeQuestion !== undefined) {
    payload.append("type_question", question.typeQuestion);
  }
  if (question.datatype !== undefined) {
    payload.append("datatype", question.datatype);
  }
  if (question.image !== undefined) {
    payload.append("image", question.image);
  }
  if (question.required !== undefined) {
    payload.append("required", question.required);
  }
  if (question.group !== undefined) {
    payload.append("group", question.group);
  }

  if (question.helpFile !== undefined)
    payload.append("help_file", question.helpFile ? question.helpFile : "");
  if (question.has_free_option !== undefined)
    payload.append("has_free_option", question.has_free_option);
  let editedQuestion = null;

  /* Edit question */
  const r = await CourseAPI.patchCourseStepQuestion(
    organizationId,
    courseId,
    stepId,
    questionId,
    payload
  );
  editedQuestion = new Question({ ...r.data });
  if (question.choices && question.choices.length) {
    /* Delete options */
    for (const id of deletedOptionsIds) {
      await CourseAPI.deleteQuestionOption(
        organizationId,
        courseId,
        stepId,
        questionId,
        id
      );
    }
    /* Edit question options */
    if (isOptionChanged) {
      for (const choice of question.choices.filter((choice) => choice.id)) {
        const choiceResult = await CourseAPI.patchCourseStepQuestionOption(
          organizationId,
          courseId,
          stepId,
          questionId,
          choice.id,
          {
            name: choice.name,
            order: choice.order,
          }
        );
        editedQuestion.choices.push(choiceResult);
      }
    }
    /* create question options */
    for (const choice of question.choices.filter((choice) => !choice.id)) {
      const choiceResult = await CourseAPI.postCourseStepQuestionOption(
        organizationId,
        courseId,
        stepId,
        questionId,
        {
          name: choice.name,
          order: choice.order,
        }
      );
      editedQuestion.choices.push(choiceResult);
    }
  }
  return editedQuestion;
}
export async function editLogicalJumps(
  organizationId,
  courseId,
  stepId,
  questionId,
  logicalJumps
) {
  for (const jump of logicalJumps) {
    if (jump.id) {
      if (jump.questionId) {
        await CourseAPI.deleteLogicalJump(
          organizationId,
          courseId,
          stepId,
          questionId,
          jump.id
        );
        await CourseAPI.postLogicalJump(
          organizationId,
          courseId,
          stepId,
          questionId,
          {
            question_to: jump.questionId,
            condition_choice: jump.optionId,
          }
        );
      } else {
        await CourseAPI.deleteLogicalJump(
          organizationId,
          courseId,
          stepId,
          questionId,
          jump.id
        );
      }
    } else if (jump.questionId) {
      await CourseAPI.postLogicalJump(
        organizationId,
        courseId,
        stepId,
        questionId,
        {
          question_to: jump.questionId,
          condition_choice: jump.optionId,
        }
      );
    }
  }
}
export function editOrganizationCourse(organizationId, courseId, course) {
  return new Promise((resolve, reject) => {
    CourseAPI.putOrganizationCourse(organizationId, courseId, course)
      .then((r) => resolve(r.data))
      .catch((r) => reject(r));
  });
}
export function editOrganizationCourseImage(organizationId, courseId, file) {
  let image = new FormData();
  image.append("image", file);
  return new Promise((resolve, reject) => {
    CourseAPI.patchOrganizationCourse(organizationId, courseId, image)
      .then((r) => resolve(r.data))
      .catch((r) => reject(r));
  });
}
export function findEligibledLogicalJumps(
  organizationId,
  courseId,
  stepId,
  questionId
) {
  return new Promise((resolve, reject) => {
    CourseAPI.getEligibledLogicalJumps(
      organizationId,
      courseId,
      stepId,
      questionId
    )
      .then((res) => resolve(res.data.results))
      .catch((err) => reject(err));
  });
}
export function findOrganizationCourse(organizationId, courseId) {
  return new Promise((resolve, reject) => {
    CourseAPI.getOrganizationCourse(organizationId, courseId)
      .then((r) => resolve(new Course(r.data)))
      .catch((r) => reject(r));
  });
}
export function findOrganizationCourseCampaigns(organizationId, courseId) {
  return CourseAPI.getOrganizationCourseCampaigns(organizationId, courseId);
}
export function findOrganizationCourses(organizationId, archived, page) {
  return CourseAPI.getOrganizationCourses(organizationId, archived, page);
}
export function findAllQuestionOptions(
  organizationId,
  courseId,
  stepId,
  questionId,
  page
) {
  return new Promise((resolve, reject) => {
    CourseAPI.getQuestionOptions(
      organizationId,
      courseId,
      stepId,
      questionId,
      page
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
export async function findQuestionOptions(
  organizationId,
  courseId,
  stepId,
  questionId
) {
  let questionOptions = [];
  async function findQuestionOptionsPage(page) {
    const res = await CourseAPI.getQuestionOptions(
      organizationId,
      courseId,
      stepId,
      questionId,
      page
    );
    if (!res) return;
    questionOptions = [...questionOptions, ...res.data.results];
    if (res.data.next) {
      await findQuestionOptionsPage(res.data.next);
    }
    return questionOptions;
  }
  await findQuestionOptionsPage(1);

  return questionOptions;
}
export function findQuestionLogicalJumps(
  organizationId,
  courseId,
  stepId,
  questionId
) {
  return new Promise((resolve, reject) => {
    CourseAPI.getQuestionsLogicalJumps(
      organizationId,
      courseId,
      stepId,
      questionId
    )
      .then((res) => resolve(res.data.results))
      .catch((err) => reject(err));
  });
}
export function moveQuestion(
  organizationId,
  courseId,
  stepId,
  questionId,
  goUp
) {
  return new Promise((resolve, reject) => {
    CourseAPI.moveQuestion(organizationId, courseId, stepId, questionId, goUp)
      .then(() => {
        CourseAPI.getOrganizationCourseStep(organizationId, courseId, stepId)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
}
export async function moveQuestionDrop({
  organizationId,
  courseId,
  stepId,
  questionId,
  newPosition,
  isGroup,
}) {
  await CourseAPI.moveQuestionDrop(
    organizationId,
    courseId,
    stepId,
    questionId,
    newPosition
  );
  if (isGroup) {
    const response = await CourseAPI.getOrganizationCourseStep(
      organizationId,
      courseId,
      stepId
    );

    return response.data.questions;
  }
}
export function moveStep(organizationId, courseId, stepId, goUp) {
  return new Promise((resolve, reject) => {
    CourseAPI.moveStep(organizationId, courseId, stepId, goUp)
      .then(() => {
        CourseAPI.getOrganizationCourse(organizationId, courseId)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
}

export function getQuestionGroups(organizationId, courseId, stepId) {
  return CourseAPI.getQuestionGroups(organizationId, courseId, stepId);
}

export function postQuestionGroup(organizationId, courseId, stepId, label) {
  return CourseAPI.postQuestionGroup(organizationId, courseId, stepId, {
    label,
  });
}

export function deleteQuestionGroup(organizationId, courseId, stepId, groupId) {
  return CourseAPI.deleteQuestionGroup(
    organizationId,
    courseId,
    stepId,
    groupId
  );
}
//#region Organization > Course > Campaign - Service
export function createOrganizationCourseCampaign(
  organizationId,
  CourseId,
  payload
) {
  return CourseAPI.createOrganizationCourseCampaign(
    organizationId,
    CourseId,
    payload
  );
}
export function fetchOrganizationCourseCampaign(
  organizationId,
  courseId,
  campaignId
) {
  return CourseAPI.getOrganizationCourseCampaign(
    organizationId,
    courseId,
    campaignId
  );
}
export function patchOrganizationCourseCampaign(
  organizationId,
  courseId,
  campaignId,
  payload
) {
  return CourseAPI.patchOrganizationCourseCampaign(
    organizationId,
    courseId,
    campaignId,
    payload
  );
}
export function deleteOrganizationCourseCampaign(
  organizationId,
  courseId,
  campaignId
) {
  return CourseAPI.deleteOrganizationCourseCampaign(
    organizationId,
    courseId,
    campaignId
  );
}
export function postOrganizationCourseCampaignInvite(
  organizationId,
  courseId,
  campaignId,
  payload
) {
  return CourseAPI.postOrganizationCourseCampaignInvite(
    organizationId,
    courseId,
    campaignId,
    payload
  );
}
//#endregion
//#region Organization > Course > Campaign > Record - Service

export function fetchOrganizationCourseCampaignRecordsAgreed(
  organizationId,
  courseId,
  campaignId,
  page
) {
  return CourseAPI.getOrganizationCourseCampaignRecordsAgreed(
    organizationId,
    courseId,
    campaignId,
    page
  );
}
export function fetchOrganizationCourseCampaignRecordsDisagreed(
  organizationId,
  courseId,
  campaignId,
  page
) {
  return CourseAPI.getOrganizationCourseCampaignRecordsDisagreed(
    organizationId,
    courseId,
    campaignId,
    page
  );
}
export function fetchOrganizationCourseCampaignRecordsInProgress(
  organizationId,
  courseId,
  campaignId,
  page
) {
  return CourseAPI.getOrganizationCourseCampaignRecordsInProgress(
    organizationId,
    courseId,
    campaignId,
    page
  );
}
//#endregion
